import React, {FC} from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";


interface DateInputProps {
    onChange: (date: Date) => void;
    value: Date | undefined;
    disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = (props) => {
    return (
        <MyDatePicker className="form-control"
                      placeholderText="dd-mm-yyyy"
                      selected={props.value}
                      onChange={props.onChange}
                      disabled={props.disabled}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown/>
    );
}

const MyDatePicker = styled(DatePicker)`
  max-width: 120px;
`;

DateInput.defaultProps = {
    disabled: false
}

