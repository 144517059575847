// material-ui
import {Theme} from '@mui/material/styles';
import {Container, Link, Stack, Typography, useMediaQuery} from '@mui/material';
import React from 'react';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
    const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="xl">
            <Stack
                direction={matchDownSM ? 'column' : 'row'}
                justifyContent={matchDownSM ? 'center' : 'space-between'}
                spacing={2}
                textAlign={matchDownSM ? 'center' : 'inherit'}
            >
                <Typography variant="subtitle2" color="secondary" component="span">
                    Satisfeed is een product van {' '}
                    <Typography
                        component={Link}
                        variant="subtitle2"
                        href="https://www.credenco.com"
                        target="_blank"
                        underline="hover">
                        Credenco B.V.
                    </Typography>
                </Typography>

                <Stack direction={matchDownSM ? 'column' : 'row'} spacing={matchDownSM ? 1 : 3}
                       textAlign={matchDownSM ? 'center' : 'inherit'}>
                    <Typography
                        variant="subtitle2"
                        color="secondary"
                        component={Link}
                        href="https://www.credenco.com#comp-lcc2nrsj"
                        target="_blank"
                        underline="hover"
                    >
                        Contact
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
};

export default AuthFooter;
