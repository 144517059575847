import React, { FC, useEffect } from 'react';
import { Box, Chip, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import { IssueType } from '../../state';

interface Props {
    selectedIssueTypes?: IssueType[];
    onSelect: (issueTypes: IssueType[]) => void;
    issueTypes?: IssueType[];
    disabled?: boolean
}

export const IssueTypeMultiSelectCombo: FC<Props> = (props) => {

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

    useEffect(() => {
        let descriptions = props.selectedIssueTypes?.filter((value) => value).map(value => value.description) as string[];
        if (descriptions) {
            setSelectedItems(descriptions);
        }
    }, [props.selectedIssueTypes]);

    const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
        const value = event.target.value;
        let values: string[] = (typeof value === 'string') ? value.split(',') : value;
        setSelectedItems(values);
        let selectedIssueTypes: IssueType[] | undefined = props.issueTypes?.filter(item => values.includes(item.description));
        if (selectedIssueTypes === undefined) {
            props.onSelect([]);
        } else {
            props.onSelect(selectedIssueTypes);
        }
    };

    return (
        <Select
            size="small"
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            style={{width: '100%', borderColor:'#FF0000'}}
            value={selectedItems}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5, borderColor:'#FF0000'}}>
                    {selected.map((value) => (
                        <Chip key={value} label={value}/>
                    ))}
                </Box>
            )}>
            {props.issueTypes?.map((item) => (
                <MenuItem
                    key={item.id}
                    value={item.description}>
                    {item.description}
                </MenuItem>
            ))}
        </Select>
    )
};

const MySelect = styled(Select)`
  width: 100%;
`;


IssueTypeMultiSelectCombo.defaultProps = {
    disabled: false
}
