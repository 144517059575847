import React, {FC, useEffect, useState} from 'react';
import {
    authSelector,
    getImpactTypes,
    getIssueTypes,
    getLocations,
    getService,
    getServiceProfiles,
    impactTypeSelector,
    issueTypeSelector,
    locationSelector,
    saveService,
    ServiceForm,
    serviceProfileSelector,
    servicesSelector,
    useAppDispatch
} from "../../../state";
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
    EmailMultiValueInput,
    ErrorAlert,
    ImpactTypeMultiSelectCombo,
    IssueTypeMultiSelectCombo,
    LocationSelectCombo
} from '../../molecules';
import {Container} from 'react-bootstrap';
import {t} from 'i18next';
import {OrderEnum} from '../../utils';
import {ImageInput} from '../../molecules/ImageInput';
import {ServiceProfileMultiSelectCombo} from '../../molecules/ServiceProfileMultiSelectCombo';
import MainCard from '../../molecules/MainCard';
import {Button, Checkbox, FormControlLabel, Grid, InputLabel, Stack, TextField} from '@mui/material';


export const ServiceDetailContent: FC = () => {
    const [serviceForm, setServiceForm] = useState<ServiceForm>(new ServiceForm());
    const dispatch = useAppDispatch();
    const {serviceExternalKey} = useParams();
    const servicesState = useSelector(servicesSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const services = useSelector(servicesSelector);
    const locations = useSelector(locationSelector);
    const impactTypes = useSelector(impactTypeSelector);
    const issueTypes = useSelector(issueTypeSelector);
    const serviceProfiles = useSelector(serviceProfileSelector);

    useEffect(() => {
        dispatch(getLocations({jwtToken: authState.jwtToken!}));
        dispatch(getServiceProfiles({jwtToken: authState.jwtToken!}));
        dispatch(getImpactTypes({
            jwtToken: authState.jwtToken!, page: 0,
            pageSize: 10000,
            sortParameters: {field: 'displayOrder', order: OrderEnum.asc}
        }));
        dispatch(getIssueTypes({
            jwtToken: authState.jwtToken!, page: 0,
            pageSize: 10000,
            sortParameters: {field: 'displayOrder', order: OrderEnum.asc}
        }));
    }, [authState.jwtToken]);

    useEffect(() => {
        if (serviceExternalKey) {
            dispatch(getService({jwtToken: authState.jwtToken!, serviceExternalKey: serviceExternalKey}));
        }
    }, [serviceExternalKey]);

    useEffect(() => {
        if (servicesState.singleItem && servicesState.singleItem.externalKey == serviceExternalKey) {
            setServiceForm(ServiceForm.from(servicesState.singleItem));
        }
    }, [servicesState.singleItem]);

    const handleSetFormValue = (param: any) => {
        setServiceForm(Object.assign({}, serviceForm, param));
    }

    function saveForm() {
        dispatch(saveService({jwtToken: authState.jwtToken!, serviceForm: serviceForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/services')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return ServiceForm.isValid(serviceForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={servicesState.error !== undefined}/>
            <MainCard title={serviceExternalKey ? 'Wijzig dienst' : 'Voeg dienst toe'}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="title">{t('fields.service.single.name')}</InputLabel>
                                <TextField type="text" rows={1} value={serviceForm.name}
                                           onChange={e => handleSetFormValue({name: e.target.value})}
                                           inputProps={{maxLength: 100}}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="serviceProfiles">{t('fields.serviceProfile.plural.typeDescription')}</InputLabel>
                                <ServiceProfileMultiSelectCombo
                                    onSelect={(serviceProfiles) => handleSetFormValue({serviceProfiles: serviceProfiles})}
                                    selectedServiceProfiles={serviceForm?.serviceProfiles}
                                    serviceProfiles={serviceProfiles.list}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="location">{t('fields.location.single.typeDescription')}</InputLabel>
                                <LocationSelectCombo onSelect={(location) => handleSetFormValue({location: location})}
                                                     selectedLocationId={serviceForm?.location?.id}
                                                     locations={locations.locations}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="issueTypes">{t('fields.service.single.allowedIssueTypes')}</InputLabel>
                                <IssueTypeMultiSelectCombo
                                    onSelect={(issueTypes) => handleSetFormValue({allowedIssueTypes: issueTypes})}
                                    issueTypes={issueTypes.list} selectedIssueTypes={serviceForm.allowedIssueTypes}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="impactTypes">{t('fields.service.single.allowedImpactTypes')}</InputLabel>
                                <ImpactTypeMultiSelectCombo
                                    onSelect={(impactTypes) => handleSetFormValue({allowedImpactTypes: impactTypes})}
                                    impactTypes={impactTypes.list} selectedImpactTypes={serviceForm.allowedImpactTypes}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="administratorEmailAddresses">{t('fields.service.single.administratorEmailAddresses')}</InputLabel>
                                <EmailMultiValueInput
                                    onChange={(value) => handleSetFormValue({administratorEmailAddresses: value})}
                                    value={serviceForm.administratorEmailAddresses}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="additionalUserEmailAddresses">{t('fields.service.single.additionalUserEmailAddresses')}</InputLabel>
                                <EmailMultiValueInput
                                    onChange={(value) => handleSetFormValue({additionalUserEmailAddresses: value})}
                                    value={serviceForm.additionalUserEmailAddresses}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="reportingBucketSize">{t('fields.service.single.reportingBucketSize')}</InputLabel>
                                <TextField type="number" value={serviceForm.reportingBucketSize}
                                           onChange={e => handleSetFormValue({reportingBucketSize: e.target.value})}
                                           inputProps={{maxLength: 100}}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="reportingBucketPeriodInMinutes">{t('fields.service.single.reportingBucketPeriodInMinutes')}</InputLabel>
                                <TextField type="number" value={serviceForm.reportingBucketPeriodInMinutes}
                                           onChange={e => handleSetFormValue({reportingBucketPeriodInMinutes: e.target.value})}
                                           inputProps={{maxLength: 100}}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="reportingBucketMarginInMinutes">{t('fields.service.single.reportingBucketMarginInMinutes')}</InputLabel>
                                <TextField type="number" value={serviceForm.reportingBucketMarginInMinutes}
                                           onChange={e => handleSetFormValue({reportingBucketMarginInMinutes: e.target.value})}
                                           inputProps={{maxLength: 100}}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="base64EncodedLogo">{t('fields.service.single.base64EncodedLogo')}</InputLabel>
                                <ImageInput existingImagePreview={serviceForm.base64EncodedLogo}
                                            onImageSelected={(image) => handleSetFormValue({base64EncodedLogo: image})}
                                            aspectRatio={2}
                                            previewImageSize={100}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <FormControlLabel control={<Checkbox/>}
                                                  label={t('fields.service.single.showInApp')}
                                                  checked={serviceForm.showInApp}
                                                  onChange={(e, checked) => handleSetFormValue({showInApp: checked})}/>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary"
                                    onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm}
                                    disabled={servicesState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};
