import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { BackendSortParams, OrderEnum } from '../../../components';
import { formatDateForTransport, formatDateTimeForTransport } from '../DateUtils';
import { MaintenanceForm } from '../maintenance';
import {SignupForm} from "./SignupForm";

export const saveSignup = createAsyncThunk(
    'signup/saveSignup', ({signupForm}: {signupForm: SignupForm}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        return axios.post('/public/signup', signupForm)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const confirmSignup = createAsyncThunk(
    'signup/confirmSignup', ({signupRequestExternalKey}: {signupRequestExternalKey: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        return axios.post('/public/signup/' + signupRequestExternalKey)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
