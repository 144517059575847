import React, { FC, useEffect } from 'react';
import { Box, Chip, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import { ImpactType } from '../../state';

interface Props {
    selectedImpactTypes?: ImpactType[];
    onSelect: (impactTypes: ImpactType[]) => void;
    impactTypes?: ImpactType[];
    disabled?: boolean
}

export const ImpactTypeMultiSelectCombo: FC<Props> = (props) => {

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

    useEffect(() => {
        let descriptions = props.selectedImpactTypes?.filter((value) => value).map(value => value.description) as string[];
        if (descriptions) {
            setSelectedItems(descriptions);
        }
    }, [props.selectedImpactTypes]);

    const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
        const value = event.target.value;
        let values: string[] = (typeof value === 'string') ? value.split(',') : value;
        setSelectedItems(values);
        let selectedImpactTypes: ImpactType[] | undefined = props.impactTypes?.filter(item => values.includes(item.description));
        if (selectedImpactTypes === undefined) {
            props.onSelect([]);
        } else {
            props.onSelect(selectedImpactTypes);
        }
    };

    return (
        <Select
            size="small"
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            style={{width: '100%', borderColor:'#FF0000'}}
            value={selectedItems}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5, borderColor:'#FF0000'}}>
                    {selected.map((value) => (
                        <Chip key={value} label={value}/>
                    ))}
                </Box>
            )}>
            {props.impactTypes?.map((item) => (
                <MenuItem
                    key={item.id}
                    value={item.description}>
                    {item.description}
                </MenuItem>
            ))}
        </Select>
    )
};

const MySelect = styled(Select)`
  width: 100%;
`;


ImpactTypeMultiSelectCombo.defaultProps = {
    disabled: false
}
