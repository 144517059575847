import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { BackendSortParams, OrderEnum } from '../../../components';
import { formatDateForTransport, formatDateTimeForTransport } from '../DateUtils';

export const getIssues = createAsyncThunk(
    'issues/getIssues', (params: {
        jwtToken: string,
        serviceExternalKey?: string | undefined,
        reportedAtFrom?: Date | undefined,
        reportedAtUntil?: Date | undefined,
        globalSearchText?: string,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/issues?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];
        if (params.serviceExternalKey) {
            url = url + '&serviceExternalKey=' + params.serviceExternalKey;
        }
        if (params.reportedAtFrom) {
            url = url + '&reportedAtFrom=' + formatDateTimeForTransport(params.reportedAtFrom);
        }
        if (params.reportedAtUntil) {
            url = url + '&reportedAtUntil=' + formatDateTimeForTransport(params.reportedAtUntil);
        }
        if (params.globalSearchText && params.globalSearchText.length > 0) {
            url = url + '&globalSearchText=' + encodeURI(params.globalSearchText);
        }

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getIssue = createAsyncThunk(
    'issues/getIssue', ({jwtToken, issueId}: { jwtToken: string, issueId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/issues/' + issueId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
