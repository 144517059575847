import React, {ReactNode, useEffect, useState} from 'react';

// third-party
import {IntlProvider, MessageFormatElement} from 'react-intl';

// project-imports
import useConfig from '../../hooks/useConfig';
import {I18n} from '../atoms/types/config';
import {getTranslations} from "../../AppTexts";

// load locales files
const loadLocaleData = (locale: I18n) => {
  switch (locale) {
    case 'en':
      return import('./locales/nl.json');
    case 'nl':
    default:
      return import('./locales/nl.json');
  }
};

// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

  useEffect(() => {
    loadLocaleData(i18n).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
      setMessages(d.default);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="nl" messages={getTranslations()}>
        {/*<IntlProvider locale={i18n} defaultLocale="nl" messages={messages}>*/}
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
