import React, {FC, useEffect, useState} from 'react';
import {t} from 'i18next';
import {QuestionTypeSelect} from '../atoms/QuestionTypeSelect';
import {FeedbackPossibleAnswer, FeedbackQuestion} from '../../state/slices/feedback';
import {PrimaryButton} from '../atoms/PrimaryButton';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import {PopupTransition} from '../atoms/Transitions';
import {Add, Trash} from 'iconsax-react';

interface Props {
    selectedQuestion?: FeedbackQuestion;
    onSave: (question: FeedbackQuestion) => void;
    onHide: () => void;
    show: boolean;
}

export const QuestionEditModalPanel: FC<Props> = ({selectedQuestion,
                                                      onSave = () => {},
                                                      onHide = () => {},
                                                    show}) => {
    const [questionForm, setQuestionForm] = useState<FeedbackQuestion>({title:'', description:'', questionType:'FIVE_STARS', enableRemarks: false});
    useEffect(() => {
        if (selectedQuestion) {
            setQuestionForm(Object.assign({}, questionForm, selectedQuestion));
        }
    }, [selectedQuestion, show]);
    const handleSetFormValue = (param: any) => {
        setQuestionForm(Object.assign({}, questionForm, param));
    }
    const handleAddPossibleAnswer = () => {
        const answer = Math.max(...questionForm.possibleAnswers!.map(answer => answer.answer), 0) + 1;
        let possibleAnswers = Object.assign([], questionForm.possibleAnswers);
        possibleAnswers.push({answer: answer, label: ''});
        setQuestionForm(Object.assign({}, questionForm, {possibleAnswers: possibleAnswers}));
    }
    const handleDeletePossibleAnswer = (deletePossibleAnswer: FeedbackPossibleAnswer) => {
        let possibleAnswers = questionForm.possibleAnswers?.filter((answer) => answer.answer !== deletePossibleAnswer.answer);
        setQuestionForm(Object.assign({}, questionForm, {possibleAnswers: possibleAnswers}));
    }
    const handleUpdatePossibleAnswer = (updatePossibleAnswer: FeedbackPossibleAnswer) => {
        let possibleAnswers = Object.assign([] as FeedbackPossibleAnswer[], questionForm.possibleAnswers);
        let index = possibleAnswers.findIndex(answer => answer.answer === updatePossibleAnswer.answer);
        if (index >= 0 && possibleAnswers) {
            possibleAnswers[index] = Object.assign({}, updatePossibleAnswer);
        }
        setQuestionForm(Object.assign({}, questionForm, {possibleAnswers: possibleAnswers}));
    }

    return (
        <Dialog
            maxWidth="sm"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            onClose={onHide}
            open={show}
            sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle >
                {selectedQuestion ? 'Wijzig vraag': 'Voeg vraag toe'}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="title">{t('feedback.form.question.title')}</InputLabel>
                                <TextField value={questionForm.title}
                                           inputProps={{ maxLength: 200 }}
                                           onChange={e => handleSetFormValue({title: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('feedback.form.question.description')}</InputLabel>
                                <TextField value={questionForm.description}
                                           inputProps={{ maxLength: 500 }} rows={5} multiline={true}
                                           onChange={e => handleSetFormValue({description: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="questionType">{t('feedback.form.question.questionType')}</InputLabel>
                                <QuestionTypeSelect selectedQuestionType={questionForm.questionType}
                                                    onChange={questionType => handleSetFormValue({questionType: questionType})}/>
                            </Stack>
                        </Grid>
                {
                    questionForm.questionType === 'CHOICE' ?
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="possibleAnswers">{t('feedback.form.question.possibleAnswers')}</InputLabel>
                                {
                                    questionForm.possibleAnswers?.map(possibleAnswer => (
                                        <Grid container spacing={1} xs={12}>
                                            <Grid item xs={11}>
                                                <TextField value={possibleAnswer.label} fullWidth={true}
                                                            onChange={e => handleUpdatePossibleAnswer({answer: possibleAnswer.answer, label: e.target.value})}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip arrow placement="right" title="Verwijderen">
                                                    <IconButton color="error" onClick={() => handleDeletePossibleAnswer(possibleAnswer)}>
                                                        <Trash />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ))}
                                <Grid container spacing={1} xs={12}>
                                    <Grid item xs={11}>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip arrow placement="right" title="Toevoegen">
                                            <IconButton color={'primary'} onClick={() => handleAddPossibleAnswer()}><Add /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid> : null
                }
                        { questionForm.questionType !== 'DESCRIPTION' &&
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <FormControlLabel control={<Checkbox />}
                                                      label={t('feedback.form.question.enableRemarks')}
                                                      checked={ questionForm.enableRemarks }
                                                      onChange={(e, checked) => handleSetFormValue({enableRemarks: checked})}
                                    />
                                </Stack>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onHide}>
                    Sluiten
                </Button>
                <PrimaryButton onClick={() => onSave(questionForm)}>
                    OK
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    )
};


