import React, {FC, PropsWithChildren} from 'react';
import {IconThumbsDown, IconThumbsUp} from "./icons";
import {WidgetGroupSelect, WidgetGroupSelectItem} from "./WidgetGroupSelect";


interface Props {
    value?: number;
    onChange: (selectedValue: number) => void;
}

export function getThumbsElement(value: number | undefined, size: string) {
    if (value === 2) {
        return <IconThumbsUp size={size}/>;
    } else if (value === 1) {
        return <IconThumbsDown size={size}/>;
    } else {
        console.log('Unexpected thumbs value: ', value);
    }
}

export const ThumbsGroupSelect: FC<Props & PropsWithChildren> = (props) => {
    return (
        <WidgetGroupSelect value={props.value} onChange={props.onChange}>
            <WidgetGroupSelectItem value={1}>{getThumbsElement(1, "64")}</WidgetGroupSelectItem>
            <WidgetGroupSelectItem value={2}>{getThumbsElement(2, "64")}</WidgetGroupSelectItem>
        </WidgetGroupSelect>
    )

};


