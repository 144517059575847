import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from "react-bootstrap";
import { t } from 'i18next';
import { getServices, Service } from '../../state';
import { QRCodeSVG } from 'qrcode.react';
import { QuestionTypeSelect } from '../atoms/QuestionTypeSelect';
import { FeedbackPossibleAnswer, FeedbackQuestion } from '../../state/slices/feedback';
import { FeedbackForm } from '../../state/slices/feedback/FeedbackForm';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Add, Delete, QrCode } from '@mui/icons-material';
import { PopupTransition } from '../atoms/Transitions';

interface Props {
    title?: string;
    onConfirm: () => void;
    onHide: () => void;
    show: boolean;
}

export const ConfirmModalPanel: FC<Props> = ({title,
                                                      onConfirm = () => {},
                                                      onHide = () => {},
                                                    show}) => {
    return (
        <Dialog
            maxWidth="sm"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            onClose={onHide}
            open={show}
            sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Weetje het zeker?</DialogTitle>
            <DialogContent>
                {title}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onHide}>
                    Sluiten
                </Button>
                <PrimaryButton onClick={() => onConfirm()}>
                    OK
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    )
};


