import React, { FC, useEffect, useMemo } from 'react';
import { authSelector, formatDateTimeForDisplay, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FeedbackRequest, feedbackSelector, getFeedbackRequests } from '../../../state/slices/feedback';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '../../atoms/PrimaryButton';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { toDistinctCommaSeperatedList } from "../../utils";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Send2 } from "iconsax-react";
import { IconResults } from '../../atoms';

interface Props {
}


export const FeedbackListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const feedback = useSelector(feedbackSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();


    const columns = useMemo<MRT_ColumnDef<FeedbackRequest>[]>(
        () => {
            return [
                {
                    header: t('feedback.form.title'),
                    accessorKey: 'title',
                },
                {
                    header: t('feedback.form.description'),
                    accessorKey: 'description',
                },
                {
                    header: t('feedback.form.startDate'),
                    accessorKey: 'startDate',
                    accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.startDate),
                },
                {
                    header: t('feedback.form.endDate'),
                    accessorKey: 'endDate',
                    accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.endDate),
                },
                {
                    header: t('fields.service.single.typeDescription'),
                    accessorFn: (originalRow) => {
                        return toDistinctCommaSeperatedList(originalRow.services, (item) => item.service.name);
                    }
                },
            ];
        },
        [],
    );

    useEffect(() => {
        dispatch(getFeedbackRequests({jwtToken: authState.jwtToken}));
    }, []);

    function add() {
        navigate('/manage/feedback/add');
    }

    function invite(id: number) {
        navigate('/manage/feedback/' + id + "/invite");
    }

    function showResults(id: any) {
        navigate('/manage/feedback/' + id + "/results");
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={feedback.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={feedback.feedbacks}
                enableRowSelection={false}
                enableColumnOrdering
                enableGlobalFilter={true}
                muiTableBodyCellProps={({cell}) => ({
                    onClick: (event) => {
                        if (cell.column.id != 'mrt-row-actions') {
                            navigate('/manage/feedback/' + cell.row.original.id);
                        }
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
                positionActionsColumn="last"
                enableRowActions={true}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                        <Tooltip arrow placement="right" title={t('feedback.list.showResults')}>
                            <IconButton color="secondary" onClick={() => {showResults(row.original.id)}}>
                                <IconResults />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title={t('feedback.list.inviteUsers')}>
                            <IconButton color="primary" onClick={() => {invite(row.original.id)}}>
                                <Send2 />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

            />
            <PrimaryButton sx={{mt: 2}} onClick={add}>{t('generic.add')}</PrimaryButton>
        </Container>
    )
};
