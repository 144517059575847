// Selectors
import { TenantConfigState } from './TenantConfigSlice';
import { ApplicationState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const attributeConfigStateSelector = (state: ApplicationState) => state.tenantConfigState;

export const tenantConfigSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    return state.tenantConfig;
});

