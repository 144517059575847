import {Location} from '../location';
import {Service} from './ServicesSlice';
import {ImpactType} from '../impacttypes';
import {IssueType} from '../issuetypes';
import {ServiceProfile} from '../serviceprofiles';

export class ServiceForm {
    externalKey?: string;
    name?: string;
    reportingBucketSize?: number = 5;
    reportingBucketPeriodInMinutes?: number = 5;
    reportingBucketMarginInMinutes?: number = 5;
    administratorEmailAddresses?: string[];
    additionalUserEmailAddresses?: string[];
    base64EncodedLogo?: string;
    location?: Location;
    allowedIssueTypes?: IssueType[];
    allowedImpactTypes?: ImpactType[];
    serviceProfiles?: ServiceProfile[];
    showInApp?: boolean = true;

    static from(service: Service): ServiceForm {
        return {
            externalKey: service.externalKey,
            name: service.name,
            reportingBucketSize: service.reportingBucketSize,
            reportingBucketPeriodInMinutes: service.reportingBucketPeriodInMinutes,
            reportingBucketMarginInMinutes: service.reportingBucketMarginInMinutes,
            administratorEmailAddresses: service.administratorEmailAddresses,
            additionalUserEmailAddresses: service.additionalUserEmailAddresses,
            base64EncodedLogo: service.base64EncodedLogo,
            location: {id: service.locationId, name: service.location} as Location,
            allowedIssueTypes: service.allowedIssueTypes,
            allowedImpactTypes: service.allowedImpactTypes,
            serviceProfiles: service.serviceProfiles,
            showInApp: service.showInApp,
        };
    }

    static isValid(ServiceForm: ServiceForm | undefined): boolean {
        if (ServiceForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }


        return true;
    }

}
