import { useEffect, useState, SyntheticEvent } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

// third-party
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';

// assets
import React from 'react';
import MainCard from '../../../components/molecules/MainCard';
import { authSelector, dashboardSelector, getIssueCounts, getServices, Service, servicesSelector, supportSelector, useAppDispatch } from '../../../state';
import { useSelector } from 'react-redux';
import { ServiceSelectCombo } from '../../molecules';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// ==============================|| CHART ||============================== //

const IssuesChart = ({ labels, data }: { labels: string[], data: any[] }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  // chart options
  const areaChartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
        borderRadiusApplication: 'end'
      }
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent']
    },
    fill: {
      opacity: [1, 0.5]
    },
    grid: {
      strokeDashArray: 4
    },
    tooltip: {
      y: {
        formatter: (val: number) => '' + val + ' melding(en)'
      }
    }
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<ChartProps>(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary.main],
      xaxis: {
        categories: labels,
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary, secondary]
          }
        },
        axisBorder: {
          show: false,
          color: line
        },
        axisTicks: {
          show: false
        },
        tickAmount: 11
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: mode === 'dark' ? 'dark' : 'light'
      }
    }));
  }, [mode, primary, secondary, line, theme, labels]);

  const [series, setSeries] = useState(data);

  useEffect(() => {
    setSeries(data);
  }, [data]);

  return <ReactApexChart options={options} series={series} type="bar" height={250} />;
};

// ==============================|| CHART WIDGET - PROJECT ANALYTICS ||============================== //

export default function ProjectAnalytics() {
  const dispatch = useAppDispatch();
  const [aantalIssuesData, setAantalIssuesData] = useState<any>({labels: [], datasets: []});
  const authState = useSelector(authSelector);
  let dashboardState = useSelector(dashboardSelector);
  const servicesState = useSelector(servicesSelector);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedServiceKey, setSelectedServiceKey] = useState<string>();
  const [selectedGroupBy, setSelectedGroupBy] = useState<string>('DAY');

  useEffect(() => {
    dispatch(getServices({jwtToken: authState.jwtToken!}));
  }, []);
  useEffect(() => {
    if (servicesState && servicesState.list && servicesState.list.length > 0) {
      setSelectedServiceKey(servicesState.list.at(0)!.externalKey);
    }
  }, [servicesState.list]);
  useEffect( () => {
    if (selectedServiceKey) {
      dispatch(getIssueCounts({jwtToken: authState.jwtToken, serviceExternalKey: selectedServiceKey, groupBy: selectedGroupBy}));
    }
  }, [selectedServiceKey, selectedGroupBy])
  useEffect(() => {
    if (dashboardState && dashboardState.issueCounts && dashboardState.issueCounts.length > 0) {
      const data = {
        labels: dashboardState.issueCounts?.map((value) => value.label),
        datasets: [
          {
            name: 'Meldingen',
            data: dashboardState.issueCounts?.map(value => value.count),
          }
        ]
      };
      setAantalIssuesData(data);
    }
  }, [dashboardState.issueCounts]);

  const handleSelectGroupBy = (event: SelectChangeEvent) => {
    setSelectedGroupBy(event.target.value as string);
  };

  const handleChangeTab = (event: SyntheticEvent, tab: number) => {
    setSelectedTab(tab);
    // setData(chartData[newValue]);
  };

  const handleSelectService = (service: Service) => {
    setSelectedServiceKey(service.externalKey);
  };

  return (
    <MainCard content={false}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="basic tabs example" sx={{ px: 3, pt: 1, '& .MuiTab-root': { mb: 0.5 } }}>
            <Tab label="Meldingen" {...a11yProps(0)} />
            {/*<Tab label="Verstoringen" {...a11yProps(1)} />*/}
          </Tabs>
        </Box>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <ServiceSelectCombo onSelect={handleSelectService} services={servicesState.list} selectedServiceExternalKey={selectedServiceKey}/>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <Select id="selectGroupBy" value={selectedGroupBy} onChange={handleSelectGroupBy}>
                        <MenuItem value="MINUTE">Uur</MenuItem>
                        <MenuItem value="HOUR">Dag</MenuItem>
                        <MenuItem value="WEEKDAY">Week</MenuItem>
                        <MenuItem value="DAY">Maand</MenuItem>
                        {/*<MenuItem value="MONTH">Jaar</MenuItem>*/}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
                <IssuesChart labels={aantalIssuesData.labels} data={aantalIssuesData.datasets} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MainCard>
  );
}
