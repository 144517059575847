// material-ui
import { useTheme } from '@mui/material/styles';
import logoIcon  from 'assets/images/logo.png';
import logoIconDark  from 'assets/images/logo.png';
import React from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
     // <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 135.25">
    <defs>
      <clipPath id="clippath">
        <rect x="17.01" y="17.01" width="90" height="101.23" fill="none"/>
      </clipPath>
    </defs>
    <g clipPath="url(#clippath)">
      <path d="m65.32,50.59c0-2.71-2.2-4.89-4.89-4.89h-10.03c-3.54,0-6.41-2.89-6.41-6.41v-9.21c0-2.69-2.18-4.89-4.89-4.89-1.62,0-3.12.8-4.03,2.14l-18.06,18.68v34.38s23.67,0,23.67,0v-12.48c0-2.69,2.2-4.89,4.89-4.89h19.75v-12.44Z" fill="#00808f"/>
      <path d="m65.32,63.03v12.48c0,2.71-2.2,4.89-4.89,4.89h-19.75v-12.48c0-2.69,2.2-4.89,4.89-4.89h19.75Z" fill="#455e66"/>
      <path d="m66.84,63.03v12.48c0,3.54-2.87,6.43-6.41,6.43h-19.75v10.92c0,2.69,2.2,4.89,4.89,4.89h10.03c3.52,0,6.41,2.87,6.41,6.41v9.19c0,2.71,2.18,4.89,4.89,4.89,1.62,0,3.12-.8,4.03-2.14l18.06-18.62v-34.44h.41-22.56Z" fill="#ffb800"/>
    </g>
  </svg>
  );
};

export default LogoIcon;
