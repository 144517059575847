import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateTimeForDisplay, getMaintenances, Maintenance, maintenanceSelector, servicesSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { getServices } from '../../../state/slices/services';
import { TitleText } from '../../atoms';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import type { ColumnFiltersState, PaginationState, SortingState, } from '@tanstack/react-table';
import { getSortParameters, OrderEnum } from '../../utils/MaterialReactTableHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { eclipsText } from '../../utils';
import { PrimaryButton } from '../../atoms/PrimaryButton';

interface Props {
}

export const MaintenancesListContent: FC<Props> = () => {
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    let {serviceExternalKey, reportedAtFrom, reportedAtUntil} = useParams();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const MaintenanceState = useSelector(maintenanceSelector);
    let authState = useSelector(authSelector);
    let servicesState = useSelector(servicesSelector);


    let title = t('fields.maintenance.plural.typeDescription');
    if (serviceExternalKey) {
        let selectedService = servicesState.list.filter(service => service.externalKey == serviceExternalKey).shift();
        if (selectedService) {
            title = title + ' van ' + selectedService.name + ((selectedService.location.length > 0) ? ', ' + selectedService.location : '');
        }
    }

    const columns = useMemo<MRT_ColumnDef<Maintenance>[]>(
        () => [
            {
                header: t('fields.maintenance.single.plannedFrom'),
                id: 'plannedFrom',
                minSize: 50,
                maxSize: 100,
                accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.plannedFrom),
            },
            {
                header: t('fields.maintenance.single.plannedUntil'),
                id: 'plannedUntil',
                minSize: 50,
                maxSize: 100,
                accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.plannedUntil),
            },
            {
                header: t('fields.service.single.typeDescription'),
                accessorKey: 'service.name',
                minSize: 50,
                maxSize: 150,
            },
            {
                header: t('fields.location.single.typeDescription'),
                accessorKey: 'service.location',
                minSize: 50,
                maxSize: 150,
            },
            {
                header: t('fields.maintenance.single.title'),
                accessorKey: 'title',
                minSize: 150,
                maxSize: 300,
            },
            {
                header: t('fields.maintenance.single.description'),
                accessorKey: 'description',
                minSize: 200,
                maxSize: 600,
                accessorFn: (originalRow) => eclipsText(originalRow.description, 200)
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
    }, []);
    useEffect(() => {
        let sortParameters = getSortParameters(sorting, {field: 'reportedAt', order: OrderEnum.desc});

        dispatch(getMaintenances({
            jwtToken: authState.jwtToken!,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortParameters
        }))
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        serviceExternalKey,
        reportedAtFrom,
        reportedAtUntil
    ]);

    function add() {
        navigate('/manage/maintenance/add');
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={MaintenanceState.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={MaintenanceState.list}
                manualFiltering
                manualPagination
                manualSorting
                enableRowSelection={false}
                enableColumnOrdering
                enableGlobalFilter={true}
                enableColumnFilters={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={MaintenanceState.totalElements}
                state={{
                    columnFilters,
                    globalFilter,
                    pagination,
                    sorting
                }}
                muiTableBodyRowProps={({row}) => ({
                    onClick: (event) => {
                        navigate('/manage/maintenance/' + row.original.id);
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
            />
            <PrimaryButton sx={{mt: 2}} onClick={add}>{t('generic.add')}</PrimaryButton>
        </Container>
    )


};
