import React, {FC, useEffect} from 'react';

interface Props {
    url: string;
}

// export const ErrorAlert: FC<Props> = (props) => {
export const ExternalRedirect: FC<Props> = (props: Props) => {
    useEffect(() => {
        window.location.href = props.url;
    }, [props.url]);

    return <h5></h5>;
};
