import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";
import { createRoot } from 'react-dom/client';

// fonts
import './assets/fonts/inter/inter.css';

// scroll bar
// import './simplebar/src/simplebar.css';
// import './slick-carousel/slick/slick.css';
// import './slick-carousel/slick/slick-theme.css';
//
// // apex-chart
// import 'assets/third-party/apex-chart.css';
// import 'assets/third-party/react-table.css';
// render(
//     <React.StrictMode>
//         <App/>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
