import React, { FC } from 'react';
import { Form } from "react-bootstrap";
import { t } from 'i18next';
import { ImpactType, Location } from '../../state';
import { ListItemText, MenuItem, Select } from '@mui/material';

interface Props {
    selectedLocationId?: number;
    onSelect: (location: Location) => void;
    locations?: Location[];
}


export const LocationSelectCombo: FC<Props> = (props) => {
    function onSelectItem(id: string|number) {
        props.onSelect(getSelectedLocation(id));
    }

    function getSelectedLocation(id: string|number):Location {
        return props.locations!.filter((location) => location.id === Number(id))[0];
    }

    function getLocationLabel(selectedId: number|string):string {
        const location = getSelectedLocation(selectedId);
        return location.name;
    }

    return (
        <Select value={props.selectedLocationId ? props.selectedLocationId : ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary={t('fields.location.single.selectA')}/>;
                    }

                    return <ListItemText primary={getLocationLabel(selected)}/>;
                }}>
            {props.locations?.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                    <ListItemText primary={location.name} />
                </MenuItem>))}
        </Select>
    )
};


