import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { globalSlice, GlobalState } from "./slices/global/GlobalStateSlice";
import { TypedUseSelectorHook, useDispatch, useSelector as useAppSelector } from "react-redux";
import { authenticationSlice, AuthenticationState } from "./slices/authentication/AuthenticationSlice";
import { servicesSlice, ServicesState } from './slices/services';
import {
    authSlice,
    AuthState,
    disruptionSlice,
    DisruptionsState,
    impactTypeSlice,
    ImpactTypeState,
    issueSlice,
    IssuesState,
    locationSlice, menuSlice, serviceProfileSlice, ServiceProfileState,
    supportSlice, SupportState,
    tenantConfigSlice,
    TenantConfigState
} from './slices';
import { feedbackSlice, FeedbackState } from './slices/feedback/FeedbackSlice';
import { LocationState } from './slices/location';
import { maintenanceSlice, MaintenanceState } from './slices/maintenance/MaintenanceSlice';
import { issueTypeSlice, IssueTypeState } from './slices/issuetypes';
import { MenuProps } from '../components/atoms/types/menu';
import {signupSlice, SignupState} from "./slices/signup";
import { dashboardSlice, DashboardState } from './slices/dashboard/DashboardSlice';

export interface ApplicationState {
    globalState: GlobalState;
    authState: AuthState;
    authentication: AuthenticationState;
    disruptionState: DisruptionsState;
    tenantConfigState: TenantConfigState;
    locationState: LocationState;
    servicesState: ServicesState;
    feedbackState: FeedbackState;
    issueState: IssuesState;
    dashboardState: DashboardState;
    maintenanceState: MaintenanceState;
    impactTypeState: ImpactTypeState;
    issueTypeState: IssueTypeState;
    supportState: SupportState;
    serviceProfileState: ServiceProfileState;
    signupState: SignupState;
    menuState: MenuProps;
}

const rootReducer = combineReducers<ApplicationState>({
    authState: authSlice.reducer,
    globalState: globalSlice.reducer,
    authentication: authenticationSlice.reducer,
    disruptionState: disruptionSlice.reducer,
    tenantConfigState: tenantConfigSlice.reducer,
    locationState: locationSlice.reducer,
    servicesState: servicesSlice.reducer,
    feedbackState: feedbackSlice.reducer,
    issueState: issueSlice.reducer,
    dashboardState: dashboardSlice.reducer,
    maintenanceState: maintenanceSlice.reducer,
    impactTypeState: impactTypeSlice.reducer,
    issueTypeState: issueTypeSlice.reducer,
    supportState: supportSlice.reducer,
    serviceProfileState: serviceProfileSlice.reducer,
    signupState: signupSlice.reducer,
    menuState: menuSlice.reducer
});

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// });

// @ts-ignore
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export const useSelector: TypedUseSelectorHook<ApplicationState> = useAppSelector;


