import React, {Fragment, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Box,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// project-imports
// assets
import {Grid1, Grid2, Grid9, TickCircle} from 'iconsax-react';
import MainCard from '../../molecules/MainCard';
import useConfig from "../../../hooks/useConfig";


// plan list
const plans = [
    {
        active: false,
        icon: <Grid9/>,
        title: 'Standard',
        description:
            'Create one end product for a client, transfer that end product to your client, charge them for your services. The license is then transferred to the client.',
        price: 69,
        permission: [0, 1]
    },
    {
        active: true,
        icon: <Grid2/>,
        title: 'Standard Plus',
        description:
            'Create one end product for a client, transfer that end product to your client, charge them for your services. The license is then transferred to the client.',
        price: 129,
        permission: [0, 1, 2, 3]
    },
    {
        active: false,
        icon: <Grid1/>,
        title: 'Extended',
        description:
            'Create one end product for a client, transfer that end product to your client, charge them for your services. The license is then transferred to the client.',
        price: 599,
        permission: [0, 1, 2, 3, 5]
    },
    {
        active: false,
        icon: <Grid1/>,
        title: 'Extended',
        description:
            'Create one end product for a client, transfer that end product to your client, charge them for your services. The license is then transferred to the client.',
        price: 599,
        permission: [0, 1, 2, 3, 5]
    }
];

const planList = [
    'One End Product', // 0
    'No attribution required', // 1
    'TypeScript', // 2
    'Figma Design Resources', // 3
    'Create Multiple Products', // 4
    'Create a SaaS Project', // 5
    'Resale Product', // 6
    'Separate sale of our UI Elements?' // 7
];

// ==============================|| PRICING ||============================== //

const Pricing = () => {
    const theme = useTheme();
    const [timePeriod, setTimePeriod] = useState(true);
    const {container} = useConfig();
    const priceListDisable = {
        opacity: 0.4,
        '& >div> svg': {
            fill: theme.palette.secondary.light
        }
    };

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item container spacing={3} xs={12}>
                    {plans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <MainCard sx={{pt: 1.75}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} textAlign="center">
                                            <Box sx={{color: 'primary.main'}}>{plan.icon}</Box>
                                            <Typography variant="h4">{plan.title}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{plan.description}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} alignItems="flex-end">
                                            {timePeriod && <Typography variant="h2">${plan.price}</Typography>}
                                            {!timePeriod &&
                                                <Typography variant="h2">${plan.price * 12 - 99}</Typography>}
                                            <Typography variant="h6" color="textSecondary">
                                                Lifetime
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant={plan.active ? 'contained' : 'outlined'} fullWidth>
                                            Order Now
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List
                                            sx={{
                                                m: 0,
                                                p: 0,
                                                '&> li': {
                                                    px: 0,
                                                    py: 0.625
                                                }
                                            }}
                                            component="ul"
                                        >
                                            {planList.map((list, i) => (
                                                <Fragment key={i}>
                                                    <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}
                                                              divider>
                                                        <ListItemIcon>
                                                            <TickCircle
                                                                size={18}
                                                                variant={!plan.permission.includes(i) ? 'Linear' : 'Bold'}
                                                                color={!plan.permission.includes(i) ? theme.palette.secondary.main : theme.palette.success.main}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={list}/>
                                                    </ListItem>
                                                </Fragment>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
};

export default Pricing;
