import React, {FC, PropsWithChildren} from 'react';
import {useSelector} from "react-redux";
import {authSelector} from "../../state";


export const IsTenantAdmin: FC<PropsWithChildren> = (props) => {
    let authState = useSelector(authSelector);
    return authState.isTenantAdmin ? (<span>{props.children}</span>) : null;

};
