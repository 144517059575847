import {createSlice} from '@reduxjs/toolkit';
import {defaultGenericPagableState, GenericPageableState} from '../model';
import {
    addGenericPageableStateSingleBuilders,
    addGenericStateListBuilders,
    addGenericStateSingleBuilders
} from '../slice';
import {confirmSignup, saveSignup} from "./SignupApi";


export interface Signup {
    externalKey: string;
    success: boolean;
}

export interface SignupState extends GenericPageableState<Signup> {
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, saveSignup);
        addGenericStateSingleBuilders(builder, confirmSignup);
    },
});

