import React, { FC } from 'react';
import { Modal } from "react-bootstrap";
import { Service } from '../../state';
import { ServiceSelectCombo } from './ServiceSelectCombo';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PopupTransition } from '../atoms/Transitions';

interface Props {
    selectedServiceExternalKey?: string;
    onSelect: (service: Service) => void;
    onHide: () => void;
    services?: Service[];
    show: boolean;
}


export const ServiceSelectModalPanel: FC<Props> = (props) => {
    return (
        <Dialog
            maxWidth="sm"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            onClose={props.onHide}
            open={props.show}
            sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle >Voeg dienst toe</DialogTitle>
            <DialogContent>
                <ServiceSelectCombo onSelect={props.onSelect} selectedServiceExternalKey={props.selectedServiceExternalKey} services={props.services}/>
            </DialogContent>
        </Dialog>
    )
};


