import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { BackendSortParams, OrderEnum } from '../../../components';
import { formatDateForTransport, formatDateTimeForTransport } from '../DateUtils';
import { MaintenanceForm } from '../maintenance';

export const getMaintenances = createAsyncThunk(
    'maintenances/getMaintenances', (params: {
        jwtToken: string,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/maintenance?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getMaintenance = createAsyncThunk(
    'maintenances/getMaintenance', ({jwtToken, maintenanceId}: { jwtToken: string, maintenanceId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/maintenance/' + maintenanceId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);


export const saveMaintenance = createAsyncThunk(
    'maintenances/saveMaintenance', ({jwtToken, maintenanceForm}: {jwtToken: string, maintenanceForm: MaintenanceForm}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        const body = {
            id: maintenanceForm.id,
            serviceExternalKey: maintenanceForm.service?.externalKey,
            title: maintenanceForm.title,
            description: maintenanceForm.description,
            plannedFrom: formatDateTimeForTransport(maintenanceForm.plannedFrom),
            plannedUntil: formatDateTimeForTransport(maintenanceForm.plannedUntil),
            notifyUsers: maintenanceForm.notifyUsers
        };
        return axios.post('/manage/maintenance', body, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
