import {getAppTheme} from '../AppTheme';
import StarRatings from 'react-star-ratings';
import React, {FC, useState} from 'react';
import {FeedbackAnswer, FeedbackPossibleAnswer, FeedbackQuestion} from '../../state/slices/feedback';
import styled from 'styled-components';
import {Button, Grid, TextareaAutosize} from "@mui/material";
import {SmileyGroupSelect} from "../atoms/SmileyGroupSelect";
import {ThumbsGroupSelect} from "../atoms/ThumbsGroupSelect";
import {DefaultText} from "../atoms";

interface Props {
    question?: FeedbackQuestion;
    changeAnswer: (answer: FeedbackAnswer) => void;
}

export const Question: FC<Props> = ({question, changeAnswer}) => {
    const [selectedRating, setSelectedRating] = useState(0);
    const [enteredText, setEnteredText] = useState('');

    function getPossibleAnswers() {
        return question?.possibleAnswers?.map((answer, index) => (
            <Grid item xs={12} key={'answer' + index}>{getAnswerButton(answer)}</Grid>
        ));
    }

    function getAnswerButton(answer: FeedbackPossibleAnswer) {
        if (selectedRating === answer.answer) {
            return (<StyledButtonSelected fullWidth onClick={() => selectRating(answer.answer)}>{answer.label}</StyledButtonSelected>)
        }else {
            return (<StyledButton fullWidth onClick={() => selectRating(answer.answer)}>{answer.label}</StyledButton>)
        }
    }

    function getSpecificRating() {
        if (question?.questionType === 'FIVE_STARS') {
            return (<Grid container><StarRatings rating={selectedRating}
                                numberOfStars={5}
                                changeRating={selectRating}
                                starRatedColor={getAppTheme().stars.ratedColor}
                                                starHoverColor={getAppTheme().stars.hoverColor}/>
            </Grid>)
        } else if (question?.questionType === 'THUMBS') {
            return <Grid container>
                <ThumbsGroupSelect value={selectedRating} onChange={selectRating}/>
            </Grid>
        } else if (question?.questionType === 'CHOICE') {
            return <Grid container>{getPossibleAnswers()}</Grid>
        } else if (question?.questionType === 'DESCRIPTION') {
            return <Grid container><StyledTextArea as="textarea" rows={5} maxLength={2500}
                                                onChange={e => enterText(e.target.value)}/></Grid>
        } else if (question?.questionType === 'SMILEY') {
            return <Grid container>
                <SmileyGroupSelect value={selectedRating} onChange={selectRating}/>
            </Grid>
        }
    }

    function enterText(text: string) {
        setEnteredText(text);
        updateAnswer(selectedRating, text);
    }

    function selectRating(rating: number) {
        setSelectedRating(rating);
        updateAnswer(rating, enteredText);
    }

    function updateAnswer(rating: number, text: string) {
        let answer = {
            questionId: question?.id,
            rating: rating,
            text: text
        } as FeedbackAnswer;
        changeAnswer(answer);
    }

    return <Grid container>
        <Grid item xs={12}><LargeText>{question?.title}</LargeText></Grid>
        <Grid item xs={12} mb={1}>{question?.description}</Grid>
        <Grid item xs={12}>{getSpecificRating()}</Grid>
        {
            question?.enableRemarks ?
                <Grid container mt={3}>
                    <Grid item xs={12}>
                        Opmerking
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextArea minRows={5} maxLength={1000}
                                        onChange={(e: { target: { value: string; }; }) => enterText(e.target.value)}/>
                    </Grid>
                </Grid> : null
        }
    </Grid>
}

const StyledButtonSelected = styled(Button)`
  text-align: left;
  font-size: 15px;
  color: #000000;
  border: 1px solid ${getAppTheme().colors.primary};
  background-color: ${getAppTheme().colors.tint2};
  font-weight: 500;
  border-radius: 10px;
  text-transform: none;
  margin-bottom: 5px;

  &.active {
    border-color: ${getAppTheme().colors.primary};
    background-color: ${getAppTheme().colors.tint2};
  }

  &:hover {
    border-color: ${getAppTheme().colors.primary};
    background-color: ${getAppTheme().colors.tint2};
  }

`;

const StyledButton = styled(Button)`
  text-align: left;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  border: 1px solid #e7e7e7;
  background-color: unset;
  border-radius: 10px;
  margin-bottom: 5px;
  text-transform: none;

  &.active {
    border-color: ${getAppTheme().colors.primary};
    background-color: ${getAppTheme().colors.tint2};
  }

  &:hover {
    border-color: ${getAppTheme().colors.primary};
    background-color: ${getAppTheme().colors.tint2};
  }
`;

const StyledTextArea = styled(TextareaAutosize)`
  border-color: ${getAppTheme().colors.primary};
  background-color: ${getAppTheme().colors.tint2};
  width: 100%;
  font-size: 15px;
`;

const LargeText = styled(DefaultText)`
  font-size: 20px;
`;
