import React, {FC, PropsWithChildren} from 'react';
import {IconFrowningFace, IconNeutralFace, IconSmillingFace} from "./icons";
import {WidgetGroupSelect, WidgetGroupSelectItem} from "./WidgetGroupSelect";


interface Props {
    value?: number;
    onChange: (selectedValue: number) => void;
}

export function getSmileyElement(value: number | undefined, size: string) {
    if (value === 3) {
        return <IconSmillingFace size={size}/>;
    } else if (value === 2) {
        return <IconNeutralFace size={size}/>;
    } else if (value === 1) {
        return <IconFrowningFace size={size}/>;
    } else {
        console.log('Unexpected smiley value: ', value);
    }
}

export const SmileyGroupSelect: FC<Props & PropsWithChildren> = (props) => {
    return (
        <WidgetGroupSelect value={props.value} onChange={props.onChange}>
            <WidgetGroupSelectItem value={1}>{getSmileyElement(1, "64")}</WidgetGroupSelectItem>
            <WidgetGroupSelectItem value={2}>{getSmileyElement(2, "64")}</WidgetGroupSelectItem>
            <WidgetGroupSelectItem value={3}>{getSmileyElement(3, "64")}</WidgetGroupSelectItem>
        </WidgetGroupSelect>
    )

};


