import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// material-ui
import {Button, Container, Grid, InputLabel, Stack, TextField} from '@mui/material';

// third-party
// project-imports
import {useAppDispatch} from '../../../state';
import {ErrorAlert} from "../../molecules";
import {t} from "i18next";
import MainCard from "../../molecules/MainCard";
import {saveSignup, SignupForm, signupSelector} from "../../../state/slices/signup";
import {useSelector} from "react-redux";

// assets

// ============================|| JWT - LOGIN ||============================ //

export const SignupCheckEmailContent = () => {

    return (
        <Container maxWidth="sm">
            <MainCard title={t('fields.signup.single.title')}
                      sx={{}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {t('signup.checkEmail')}
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
};
