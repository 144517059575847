import React, {FC, useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {useDropzone} from "react-dropzone";
import {IconUpload} from "../atoms";
import {ImageCropDialog} from "./ImageCropDialog";
import {useTranslation} from 'react-i18next';
import {Grid} from "@mui/material";

interface ImageProps {
    existingImagePreview?: string;
    onImageSelected: (image: string) => void;
    aspectRatio?: number;
    previewImageSize?: number;
}

export const ImageInput: FC<ImageProps> = (props) => {
    const [imagePreview, setImagePreview] = useState(props.existingImagePreview);
    const [imageFile, setImageFile] = useState<File>();
    const [showCropDialog, setShowCropDialog] = useState(false);
    const {t} = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setShowCropDialog(true);
        setPhotoSelected(acceptedFiles[0]);
    }, [])
    const {
        getRootProps,
        getInputProps
    } = useDropzone({onDrop, accept: {'image/*': []}});

    useEffect(() => {
        setImagePreview(props.existingImagePreview);
    }, [props.existingImagePreview]);

    function setPhotoSelected(file: File) {
        setImageFile(file);
        setShowCropDialog(true);
    }

    function onCropConfirmClicked(croppedImage: File) {
        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            let image = reader.result as string;
            setImagePreview(image);
            props.onImageSelected(image);
            setShowCropDialog(false);
        };
        reader.readAsDataURL(croppedImage);
    }

    function onCropCancelClicked() {
        setShowCropDialog(false);
    }

    return (
        <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={4}>
                <ArrowedSquare {...getRootProps()}>
                    <Grid container justifyContent="center" alignItems="center" direction="column" >
                        <input {...getInputProps()} />
                        <IconUpload size="100" color="#ced4da"/>
                        <p className="text-center">{t('generic.imageinput.dragText')}</p>
                    </Grid>
                </ArrowedSquare>
            </Grid>
            <Grid item xs={8} pl={3}>
                {imageFile && (
                    <ImageCropDialog correlation="Q" title={t('generic.imageinput.crop')} body="Q"
                                     showDialog={showCropDialog}
                                     onConfirm={onCropConfirmClicked} onCancel={onCropCancelClicked}
                                     imageFile={imageFile} maxHeight={300}
                                     aspectRatio={props.aspectRatio}
                    />
                )}
                <img width={props.previewImageSize} src={imagePreview} alt=""/>
            </Grid>
        </Grid>
    );
}

const ArrowedSquare = styled.div`
  &:focus {
    outline: none;
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  padding: 20px;
  padding-bottom: 0px;
  border: #ced4da solid 2px;

  border-style: dashed;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
`;

ImageInput.defaultProps = {
    aspectRatio: 0.71,
    previewImageSize: 300
}
