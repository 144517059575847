import React, { FC, useState } from 'react';
import { authAuthenticate, authSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { TitleText } from '../../atoms';
import { PrimarySubmitButton } from '../../atoms/PrimarySubmitButton';
import styled from 'styled-components';
import { ErrorAlert } from '../../molecules';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from '../../molecules/auth/AuthWrapper';
import { Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthLogin } from './AuthLogin';

interface Props {
}


export const LoginAuthenticateContent: FC<Props> = () => {
    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">Login</Typography>
                        <Typography
                            component={Link}
                            to={'/register'}
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Nog geen account?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AuthLogin />
                </Grid>
            </Grid>
        </AuthWrapper>
    )


};

const LoginScreen = styled.div`
    display: table;
    margin: auto;
    padding:30px; 
`;

const InputCode = styled(Form.Control)`
    margin: 10px;
    padding:5px; 
    width: 50px;
    height: 50px;
    text-align: center; 
    -webkit-appearance: none;
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

const DigitRow = styled(Form.Group)`
    display: flex;
    justify-content: center; 
`;
