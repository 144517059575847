import React, {FC, useState} from 'react';
import {authPrepare, authSelector, useAppDispatch} from "../../../state";
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import {Grid, Stack, Typography} from '@mui/material';
import AuthWrapper from '../../molecules/auth/AuthWrapper';
import AuthPrepare from './AuthPrepare';

interface Props {
}


export const LoginPrepareContent: FC<Props> = (navigation: any) => {
    const dispatch = useAppDispatch();
    const [emailAddress, setEmailAddress] = useState<string>();
    const authState = useSelector(authSelector);
    const navigate = useNavigate();

    function handleSubmit(event: React.SyntheticEvent) {
        event.preventDefault();
        dispatch(authPrepare({emailAddress: '' + emailAddress}))
            .then(value => {
                if (value.type.includes('fulfilled')) {
                    navigate('/authenticate');
                }
            })
    }

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                           sx={{mb: {xs: -0.5, sm: 0.5}}}>
                        <Typography variant="h3">Login</Typography>
                        <Typography
                            component={Link}
                            to={'/register'}
                            variant="body1"
                            sx={{textDecoration: 'none'}}
                            color="primary"
                        >
                            Nog geen account?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AuthPrepare/>
                </Grid>
            </Grid>
        </AuthWrapper>

        //
        // <LoginScreen>
        //         <Form onSubmit={handleSubmit}>
        //             <ErrorAlert
        //                 errorMessage={t('error.retrievingData')}
        //                 errorCode={authState.errorCode}
        //                 show={authState.error !== undefined}/>
        //             <TitleText>Login met uw zakelijke email adres</TitleText>
        //             <Form.Group as={Row} controlId="userRemarks" className="mt-5">
        //                 <Form.Label column md="3">Email adres</Form.Label>
        //                 <Col md="9">
        //                     <Form.Control type="email" placeholder="Enter e-mail"
        //                                   value={emailAddress}
        //                                   onChange={e => setEmailAddress(e.target.value)}/>
        //                 </Col>
        //             </Form.Group>
        //             <Form.Group as={Row} className="float-end">
        //                 <Col>
        //                     <PrimarySubmitButton className="mt-3 default float-right" value={'Volgende'} disabled={false}/>
        //                 </Col>
        //             </Form.Group>
        //         </Form>
        //     </LoginScreen>
    )


};

const LoginScreen = styled.div`
  display: table;
  margin: auto;
  padding: 30px;
`;
