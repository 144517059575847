export interface AppTexts {
    appName: {
        displayName: string;
        name: string;
    };
    busyInitializing: string;
    menu: {
        home: string;
        dashboard: string;
        locations: string;
        services: string;
        feedback: string;
        issues: string;
        disruptions: string;
        maintenance: string;
        support: string;
        staticdata: string;
        impactTypes: string;
        issueTypes: string;
        manage: string;
        monitor: string;
        referenceData: string;
        serviceProfiles: string;
    };
    generic: {
        yes: string;
        no: string;
        ok: string;
        cancel: string;
        add: string;
        save: string;
        delete: string;
        back: string;
        loading: string;
        downloadAsExcel: string;
        form: {
            add: string;
            edit: string;
        }
        imageinput: {
            crop: string;
            dragText: string;
        }
        time: string;
    };
    error: {
        retrievingData: string;
        errorCodes: {
            "ERR-1": string;
            "ERR-2": string;
            "ERR-4": string;
            "ERR-6": string;
        };
        invalidEmail: string;
    };
    dashboard: {
        title: string;
    }
    fields: {
        disruption: {
            single: {
                typeDescription: string;
                description: string;
                dominantIssueTypeDescription: string;
                dominantImpactTypeDescription: string;
                startedAt: string;
                endedAt: string;
                issueCount: string;
                notifyUsers: string;
            },
            plural: {
                typeDescription: string;
            }

        },
        issue: {
            single: {
                typeDescription: string;
                description: string;
                attachment: string;
                reportedBy: string;
                reportedAt: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        feedback: {
            single: {
                typeDescription: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        issueType: {
            single: {
                typeDescription: string;
                description: string;
                logo: string;
                displayOrder: string;
                selectA: string
            },
            plural: {
                typeDescription: string;
            }
        }
        impactType: {
            single: {
                typeDescription: string;
                description: string;
                logo: string;
                displayOrder: string;
                selectA: string
            },
            plural: {
                typeDescription: string;
            }
        }
        serviceProfile: {
            single: {
                typeDescription: string;
                selectA: string;
                name: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        location: {
            single: {
                typeDescription: string;
                selectA: string
            },
            plural: {
                typeDescription: string;
            }
        }
        maintenance: {
            single: {
                typeDescription: string;
                title: string;
                description: string;
                plannedFrom: string;
                plannedUntil: string;
                reportedBy: string;
                reportedAt: string;
                updatedBy: string;
                updatedAt: string;
                notifyUsers: string;
            },
            plural: {
                typeDescription: string;
            }
        }
        signup: {
            single: {
                typeDescription: string;
                title: string;
                companyName: string;
                contactPersonName: string;
                contactPersonEmailAddress: string;
                subscriptionType: string;
            }
        }
        service: {
            single: {
                typeDescription: string;
                name: string;
                location: string;
                allowedImpactTypes: string;
                allowedIssueTypes: string;
                selectA: string;
                reportingBucketSize: string;
                reportingBucketPeriodInMinutes: string;
                reportingBucketMarginInMinutes: string;
                base64EncodedLogo: string;
                administratorEmailAddresses: string;
                showInApp: string;
            },
            plural: {
                typeDescription: string;
            }
        }
        support: {
            single: {
                typeDescription: string;
                title: string;
                body: string;
                supportType: string;
            },
            plural: {
                typeDescription: string;
            }
        },
        supportType: {
            CALL: string;
            MAIL: string;
            FAQ: string;
        }
    };

    support: {
        form: {
            singleService: string;
            allServices: string;
            visibleToService: string;
        }
    }
    feedback: {
        form: {
            add: string,
            edit: string,
            services: string,
            title: string;
            description: string;
            thankYouDescription: string;
            startDate: string;
            endDate: string;
            image: string;
            feedbackType: string;
            qrCode: string;
            results: string;
            question: {
                title: string;
                description: string;
                questionType: string;
                possibleAnswers: string;
                questionTypes: {
                    FIVE_STARS: string;
                    THUMBS: string;
                    CHOICE: string;
                    DESCRIPTION: string;
                    SMILEY: string;
                }
                enableRemarks: string;
            }
        },
        list: {
            inviteUsers: string;
            showResults: string;
        }
        results: {
            count: string;
            details: string;
            noresults: string;
            deleted: string;
        }
        feedbackType: {
            five_stars: string,
            thumbs: string,
        }
        invite: {
            title: string;
            intro: string;
            allUsers: string;
            onlyUsersWithoutCompletedFeedback: string;
            onlyNewUsers: string;
            usersInvitedMessage: string;
        }
    }
    signup: {
        checkEmail: string;
        confirming: string;
        completed: string;
    };
}

const getAppTexts = (): AppTexts => {
    return {
        appName: {
            name: 'ServiceMelder',
            displayName: 'Service Melder',
        },
        busyInitializing: 'Bezig met initialiseren',
        menu: {
            home: 'Home',
            dashboard: 'Dashboard',
            locations: 'Locaties',
            services: 'Diensten',
            feedback: 'Peilingen',
            issues: 'Meldingen',
            disruptions: 'Verstoringen',
            maintenance: 'Onderhoud',
            support: 'Support/FAQ',
            staticdata: 'Stamgegevens',
            impactTypes: 'Impact types',
            issueTypes: 'Issue types',
            monitor: 'Monitor',
            manage: 'Beheer',
            referenceData: 'Stamgegevens',
            serviceProfiles: 'Medewerker profielen'

        },
        generic: {
            yes: 'Ja',
            no: 'Nee',
            ok: 'OK',
            cancel: 'Annuleren',
            add: 'Toevoegen',
            save: 'Opslaan',
            delete: 'Verwijderen',
            back: 'Terug',
            downloadAsExcel: 'Download Excel',
            loading: 'Laden...',
            form: {
                add: "Toevoegen",
                edit: "Wijzig"
            },
            imageinput: {
                crop: 'Bijsnijden',
                dragText: 'Sleep foto naar dit vak of klik hier om een foto te selecteren'
            },
            time: 'Tijd'
        },
        error: {
            retrievingData: "Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer.",
            errorCodes: {
                "ERR-1": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.',
                "ERR-2": 'Uw validatiecode is niet (meer) geldig.',
                "ERR-4": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.',
                "ERR-6": 'Uw heeft geen toegang tot dit systeem.'
            },
            invalidEmail: "Ongeldig e-mailadres."
        },
        dashboard: {
            title: 'Dashboard'
        },
        fields: {
            disruption: {
                single: {
                    typeDescription: 'Verstoring',
                    dominantIssueTypeDescription: 'Meest voorkomende gemelde probleem',
                    dominantImpactTypeDescription: 'Meest voorkomende gemelde impact',
                    description: 'Toelichting',
                    startedAt: 'Startdatum',
                    endedAt: 'Einddatum',
                    issueCount: 'Aantal meldingen',
                    notifyUsers: 'Stuur een push notification met de verstoring naar alle gebruikers van deze dienst.'
                },
                plural: {
                    typeDescription: 'Verstoringen'
                }

            },
            feedback: {
                single: {
                    typeDescription: 'Peiling'
                },
                plural: {
                    typeDescription: 'Peilingen'
                }
            },
            issue: {
                single: {
                    typeDescription: 'Melding',
                    description: 'Omschrijving',
                    attachment: 'Foto',
                    reportedBy: 'Gemeld door',
                    reportedAt: 'Gemeld op',
                },
                plural: {
                    typeDescription: 'Meldingen',
                }

            },
            issueType: {
                single: {
                    typeDescription: 'Soort melding',
                    selectA: 'Selecteer een melding',
                    description: 'Omschrijving',
                    logo: 'Afbeelding',
                    displayOrder: 'Volgorde'
                },
                plural: {
                    typeDescription: 'Soort melding',
                }
            },
            impactType: {
                single: {
                    typeDescription: 'Impact',
                    selectA: 'Selecteer impact',
                    description: 'Omschrijving',
                    logo: 'Afbeelding',
                    displayOrder: 'Volgorde'
                },
                plural: {
                    typeDescription: 'Impact types',
                }
            },
            location: {
                single: {
                    typeDescription: 'Locatie',
                    selectA: 'Selecteer locatie'
                },
                plural: {
                    typeDescription: 'Locaties'
                }
            },
            maintenance: {
                single: {
                    typeDescription: 'Onderhoud',
                    title: 'Titel',
                    description: 'Omschrijving',
                    plannedFrom: 'Gepland vanaf',
                    plannedUntil: 'Gepland tot',
                    reportedBy: 'Gemeld door',
                    reportedAt: 'Gemeld op',
                    updatedBy: 'Bijgewerkt door',
                    updatedAt: 'Bijgewerkt op',
                    notifyUsers: 'Stuur een push notification met de mededeling naar alle gebruikers van deze dienst.'
                },
                plural: {
                    typeDescription: 'Onderhoud'
                }
            },
            signup: {
                single: {
                    typeDescription: 'Registreren',
                    title: 'Registreren',
                    companyName: 'Naam bedrijf',
                    contactPersonName: 'Naam contactpersoon',
                    contactPersonEmailAddress: 'Email adres contactpersoon',
                    subscriptionType: "Abonnementstype"
                },
            },
            service: {
                single: {
                    typeDescription: 'Dienst',
                    name: 'Dienst',
                    location: 'Locatie',
                    allowedImpactTypes: 'Toegestane impact types',
                    allowedIssueTypes: 'Toegestane issue types',
                    reportingBucketSize: 'Minimaal aantal melding voordat verstoring wordt aangemaakt',
                    reportingBucketPeriodInMinutes: 'Minimale verstoring duur',
                    reportingBucketMarginInMinutes: 'Aantal minuten voordat een verstoring wordt beeindigd',
                    base64EncodedLogo: 'Logo',
                    administratorEmailAddresses: 'E-mailadressen administrators',
                    additionalUserEmailAddresses: 'E-mailadressen extra gebruikers',
                    selectA: 'Selecteer een dienst',
                    showInApp: 'Toon in App'
                },
                plural: {
                    typeDescription: 'Diensten',
                }
            },
            serviceProfile: {
                single: {
                    typeDescription: 'Medewerker profiel',
                    selectA: 'Selecteer profiel',
                    name: 'Omschrijving'
                },
                plural: {
                    typeDescription: 'Medewerker profielen'
                }
            },
            support: {
                single: {
                    typeDescription: 'Support',
                    title: 'Titel',
                    body: 'Omschrijving',
                    supportType: 'Icon type'
                },
                plural: {
                    typeDescription: 'Support items'
                }
            },
            supportType: {
                CALL: 'Telefoon',
                MAIL: 'E-mail',
                FAQ: 'Information'
            }
        },
        support: {
            form: {
                singleService: 'Enkele dienst',
                allServices: 'Alle diensten',
                visibleToService: 'Zichtbaar voor'
            }
        },
        feedback: {
            form: {
                add: 'Voeg peiling toe',
                edit: 'Wijzig peiling',
                services: 'Diensten',
                title: 'Titel',
                description: 'Omschrijving',
                thankYouDescription: 'Bedankt bericht',
                image: 'Foto',
                startDate: 'Startdatum',
                endDate: 'Einddatum',
                feedbackType: 'Soort',
                qrCode: 'QR-code',
                results: 'Resultaten',
                question: {
                    title: 'Titel',
                    description: 'Omschrijving',
                    questionType: 'Type vraag',
                    possibleAnswers: 'Mogelijke antwoorden',
                    questionTypes: {
                        FIVE_STARS: 'Vijf sterren',
                        THUMBS: 'Duimpjes',
                        CHOICE: 'Keuzelijst',
                        DESCRIPTION: 'Opmerking',
                        SMILEY: 'Smileys'
                    },
                    enableRemarks: 'Opmerkingen mogelijk?'
                }
            },
            list: {
                inviteUsers: 'Gebruikers uitnodigen',
                showResults: 'Toon resultaten'
            },
            results: {
                count: 'Aantal resultaten',
                details: 'Resultaten',
                noresults: 'Er zijn nog geen resultaten beschikbaar',
                deleted: 'De geselecteerde peiling resultaten zijn verwijderd'
            },
            feedbackType: {
                five_stars: 'Vijf sterren',
                thumbs: 'Duimpjes',
            },
            invite: {
                title: 'Versturen peiling',
                intro: 'U kunt actief reacties vragen aan personen die de app hebben geïnstalleerd. Selecteer wie u een uitnodiging wilt sturen.',
                allUsers: "Iedereen die afnemer is van een dienst van deze peiling",
                onlyUsersWithoutCompletedFeedback: "Iedereen die de peiling nog niet heeft afgerond",
                onlyNewUsers: "Iedereen die nog niet eerder is uitgenodigd voor deze peiling",
                usersInvitedMessage: "Peilingen zijn verzonden"
            }

        },
        signup: {
            checkEmail: 'We hebben zojuist een email naar u verstuurd. Gebruik de link in de email om de registratie te bevestigen.',
            confirming: 'De registratie wordt afgerond. Moment geduld a.u.b.',
            completed: 'De registratie is afgerond. U kunt nu inloggen.'
        }

    } as AppTexts;
};

export interface Translation {
    textKey: string;
    textValue: string;
}


function stripInitialDot(key: string): string {
    return (key.charAt(0) === '.') ? key.substring(1) : key;
}

const flatten: (object: any, prefix?: string) => any = (object, prefix = '') =>
    Object.keys(object).reduce(
        (prev, element) =>
            object[element] &&
            typeof object[element] === 'object' &&
            !Array.isArray(object[element])
                ? {...prev, ...flatten(object[element], `${prefix}${element}.`)}
                : {...prev, ...{[`${prefix}${element}`]: object[element]}},
        {},
    );

export const getTranslations = (): {} => {
    return flatten(getAppTexts(), '');
}
