import React, {FC} from 'react';
import {t} from 'i18next';
import {Service} from '../../state';
import {ListItemText, MenuItem, Select} from '@mui/material';

interface Props {
    selectedServiceExternalKey?: string;
    onSelect: (service: Service) => void;
    services?: Service[];
    disabled?: boolean;
}


export const ServiceSelectCombo: FC<Props> = (props) => {
    function onSelectItem(serviceKey: string) {
        props.onSelect(props.services!.filter((service) => service.externalKey === serviceKey)[0]);
    }

    function getServiceLabel(selectedService: string):string {
        const service = props.services!.filter((service) => service.externalKey === selectedService)[0];
        return (service) ? service.name + (service.location ? ' - ' + service.location : '') : '';
    }

    return (
        <Select value={props.selectedServiceExternalKey ? props.selectedServiceExternalKey : ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                disabled={props.disabled}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary={t('fields.service.single.selectA')}/>;
                    }

                    return <ListItemText primary={getServiceLabel(selected)}/>;
                }}>
            {props.services?.map((service) => (
                <MenuItem key={service.externalKey} value={service.externalKey}>
                    <ListItemText primary={service.name + (service.location ? ' - ' + service.location : '')} />
                </MenuItem>))}
        </Select>
    )
};

ServiceSelectCombo.defaultProps = {
    disabled: false
}
