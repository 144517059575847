import React, { FC, useEffect } from 'react';
import { Box, Chip, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import { IssueType, ServiceProfile } from '../../state';

interface Props {
    selectedServiceProfiles?: ServiceProfile[];
    onSelect: (serviceProfiles: ServiceProfile[]) => void;
    serviceProfiles?: ServiceProfile[];
    disabled?: boolean
}

export const ServiceProfileMultiSelectCombo: FC<Props> = (props) => {

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

    useEffect(() => {
        let names = props.selectedServiceProfiles?.filter((value) => value).map(value => value.name) as string[];
        if (names) {
            setSelectedItems(names);
        }
    }, [props.selectedServiceProfiles]);

    const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
        const value = event.target.value;
        let values: string[] = (typeof value === 'string') ? value.split(',') : value;
        setSelectedItems(values);
        let selectedServiceProfiles: ServiceProfile[] | undefined = props.serviceProfiles?.filter(item => values.includes(item.name));
        if (selectedServiceProfiles === undefined) {
            props.onSelect([]);
        } else {
            props.onSelect(selectedServiceProfiles);
        }
    };

    return (
        <Select
            size="small"
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            style={{width: '100%', borderColor:'#FF0000'}}
            value={selectedItems}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5, borderColor:'#FF0000'}}>
                    {selected.map((value) => (
                        <Chip key={value} label={value}/>
                    ))}
                </Box>
            )}>
            {props.serviceProfiles?.map((item) => (
                <MenuItem
                    key={item.externalKey}
                    value={item.name}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    )
};

const MySelect = styled(Select)`
  width: 100%;
`;


ServiceProfileMultiSelectCombo.defaultProps = {
    disabled: false
}
