import React, {FC, useEffect, useMemo} from 'react';
import {authSelector, Service, useAppDispatch} from "../../../state";
import {useSelector} from 'react-redux';
import {Container} from 'react-bootstrap';
import {getServices, servicesSelector} from '../../../state/slices/services';
import {ErrorAlert, IsTenantAdmin} from '../../molecules';
import {t} from 'i18next';
import type {MRT_ColumnDef} from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import {useNavigate} from 'react-router-dom';
import {PrimaryButton} from '../../atoms/PrimaryButton';

// import { useKeycloak } from "@react-keycloak/web";

interface Props {
}

export const ServicesListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const services = useSelector(servicesSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const columns = useMemo<MRT_ColumnDef<Service>[]>(
        () => [
            {
                header: t('fields.service.single.name'),
                accessorKey: 'name',
            },
            {
                header: t('fields.service.single.location'),
                accessorKey: 'location',
            },
            {
                header: t('fields.service.single.administratorEmailAddresses'),
                accessorFn: (originalRow) => originalRow.administratorEmailAddresses.join(", "),
            },
            {
                header: t('fields.service.single.showInApp'),
                accessorFn: (originalRow) => originalRow.showInApp ? t('generic.yes') : t('generic.no'),
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken}));
    }, []);


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={services.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={services.list}
                enableRowSelection={false}
                enableColumnOrdering
                enableGlobalFilter={true}
                muiTableBodyRowProps={({row}) => ({
                    onClick: (event) => {
                        navigate('/manage/service/' + row.original.externalKey);
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}

            />
            <IsTenantAdmin>
                <PrimaryButton sx={{mt: 2}} onClick={() => {navigate('/manage/service/add');}}>{t('generic.add')}</PrimaryButton>
            </IsTenantAdmin>
        </Container>
    )


};
