import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import React from 'react';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; Credenco B.V. All rights reserved.</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Typography
            variant="subtitle2"
            color="secondary"
            component={Link}
            href="https://www.credenco.com#comp-lcc2nrsj"
            target="_blank"
            underline="hover"
        >Support</Typography>
    </Stack>
  </Stack>
);

export default Footer;
