import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

// material-ui
import {Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack} from '@mui/material';

// third-party
import * as Yup from 'yup';
import {Formik} from 'formik';

// project-imports
import useScriptRef from '../../../hooks/useScriptRef';
import {authPrepare, authSelector, useAppDispatch} from '../../../state';
import AnimateButton from '../../atoms/AnimateButton';
import {ErrorAlert} from "../../molecules";
import {useSelector} from "react-redux";
import {t} from 'i18next';

// assets

// ============================|| JWT - LOGIN ||============================ //

const AuthPrepare = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const scriptedRef = useScriptRef();
    const authState = useSelector(authSelector);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                })}
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                    try {
                        console.log('Logging in');
                        dispatch(authPrepare({emailAddress: '' + values.email}))
                            .then(value => {
                                if (scriptedRef.current) {
                                    setStatus({success: true});
                                    setSubmitting(false);
                                }
                                if (value.type.includes('fulfilled')) {
                                    navigate('/authenticate');
                                }
                            })
                    } catch (err: any) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({success: false});
                            setErrors({submit: err.message});
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login">Email Adres</InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter email address"
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                    <ErrorAlert
                                        errorMessage={t('error.retrievingData')}
                                        errorCode={authState.errorCode}
                                        show={authState.error !== undefined}/>
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{mt: -1}}>
                            </Grid>
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button disableElevation disabled={isSubmitting} fullWidth size="large"
                                            type="submit" variant="contained" color="primary">
                                        Volgende
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthPrepare;
