import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateTimeForDisplay, getDisruptions, Disruption, disruptionsSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { getServices } from '../../../state/slices/services';
import { TitleText } from '../../atoms';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import type { ColumnFiltersState, PaginationState, SortingState, } from '@tanstack/react-table';
import { getSortParameters, OrderEnum } from '../../utils/MaterialReactTableHelpers';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { eclipsText } from '../../utils';

// import { useKeycloak } from "@react-keycloak/web";

interface Props {
}

export const DisruptionsListContent: FC<Props> = () => {
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const disruptionsState = useSelector(disruptionsSelector);
    let authState = useSelector(authSelector);

    const columns = useMemo<MRT_ColumnDef<Disruption>[]>(
        () => [
            {
                header: t('fields.disruption.single.startedAt'),
                id: 'startedAt',
                accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.startedAt),
            },
            {
                header: t('fields.disruption.single.endedAt'),
                id: 'endedAt',
                accessorFn: (originalRow) => formatDateTimeForDisplay(originalRow.endedAt),
            },
            {
                header: t('fields.service.single.typeDescription'),
                accessorKey: 'service.name',
            },
            {
                header: t('fields.service.single.location'),
                accessorKey: 'service.location',
            },
            {
                header: t('fields.disruption.single.description'),
                accessorKey: 'description',
                accessorFn: (originalRow) => eclipsText(originalRow.description, 50)
            },
            {
                header: t('fields.disruption.single.dominantIssueTypeDescription'),
                accessorKey: 'dominantIssueTypeDescription',
            },
            {
                header: t('fields.disruption.single.dominantImpactTypeDescription'),
                accessorKey: 'dominantImpactTypeDescription',
            },
            {
                header: t('fields.disruption.single.issueCount'),
                accessorKey: 'issueCount',
                accessorFn: (originalRow) => ((originalRow.issueCount > 0) ? <TextFormattedAsLink>{originalRow.issueCount}</TextFormattedAsLink> : originalRow.issueCount)
            }
        ],
        [],
    );
    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
    }, []);
    useEffect(() => {
        let sortParameters = getSortParameters(sorting, {field: 'startedAt', order: OrderEnum.desc});

        dispatch(getDisruptions({
            jwtToken: authState.jwtToken!,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            globalSearchText: globalFilter,
            sortParameters
        }))
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    function addDisruption() {
        navigate('/manage/disruption/add');
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={disruptionsState.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={disruptionsState.list}
                manualFiltering
                manualPagination
                manualSorting
                enableRowSelection={false}
                enableColumnOrdering
                enableGlobalFilter={true}
                enableColumnFilters={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={disruptionsState.totalElements}
                state={{
                    columnFilters,
                    globalFilter,
                    pagination,
                    sorting
                }}
                muiTableBodyCellProps={({column, row}) => ({
                    onClick: (event) => {
                        if ((column.id == 'issueCount') && (row.original.issueCount > 0)) {
                            let url = '/dashboard/issues/' + row.original.service.externalKey + '/' + row.original.startedAt;
                            if (row.original.endedAt) {
                                url = url + '/' + row.original.endedAt;
                            }
                            navigate(url);
                        } else {
                            let url = '/manage/disruption/' + row.original.id
                            navigate(url);
                        }
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
            />
            <PrimaryButton sx={{mt: 2}} onClick={addDisruption}>{t('generic.add')}</PrimaryButton>
        </Container>
    )


};

const TextFormattedAsLink = styled.div`
  text-decoration: underline;
  color: #007bc7;
`;
const TextDescription = styled.div`
  //overflow: hidden;
  //text-overflow: ellipsis;
  max-lines: 2;
`;
