import React, { FC, useEffect, useState } from 'react';
import {
    authSelector,
    formatDateTimeForDisplay,
    getMaintenance,
    getServices,
    MaintenanceForm,
    maintenanceSelector,
    saveMaintenance,
    servicesSelector,
    useAppDispatch
} from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTimeInput, ErrorAlert, ServiceSelectCombo } from '../../molecules';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { TitleText } from '../../atoms';
import { t } from 'i18next';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import MainCard from '../../molecules/MainCard';
import { Button, FormControl, Grid, InputLabel, Stack, TextField } from '@mui/material';


export const MaintenanceDetailContent: FC = () => {
    const [maintenanceForm, setMaintenanceForm] = useState<MaintenanceForm>(new MaintenanceForm());
    const dispatch = useAppDispatch();
    const {maintenanceId} = useParams();
    const maintenanceState = useSelector(maintenanceSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const services = useSelector(servicesSelector);

    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
    }, []);
    useEffect(() => {
        if (maintenanceId) {
            dispatch(getMaintenance({jwtToken: authState.jwtToken!, maintenanceId: maintenanceId}));
        }
    }, [maintenanceId]);
    useEffect(() => {
        if (maintenanceState.singleItem && maintenanceState.singleItem.id == Number(maintenanceId)) {
            setMaintenanceForm(MaintenanceForm.from(maintenanceState.singleItem));
        }
    }, [maintenanceState.singleItem]);

    const handleSetFormValue = (param: any) => {
        setMaintenanceForm(Object.assign({}, maintenanceForm, param));
    }


    function saveForm() {
        dispatch(saveMaintenance({jwtToken: authState.jwtToken!, maintenanceForm: maintenanceForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/maintenance/list')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return MaintenanceForm.isValid(maintenanceForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={maintenanceState.error !== undefined}/>
            <MainCard title={maintenanceId ? 'Wijzig onderhoud': 'Voeg onderhoud toe'}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="service">{t('fields.service.single.typeDescription')}</InputLabel>
                                <FormControl>
                                    <ServiceSelectCombo onSelect={(service) => handleSetFormValue({service: service})}
                                                        selectedServiceExternalKey={maintenanceForm?.service?.externalKey} services={services.list}/>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="title">{t('fields.maintenance.single.title')}</InputLabel>
                                <TextField rows={1} value={maintenanceForm.title}
                                  onChange={e => handleSetFormValue({title: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('fields.maintenance.single.description')}</InputLabel>
                                <TextField rows={5} multiline value={maintenanceForm.description}
                                           onChange={e => handleSetFormValue({description: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="plannedFrom">{t('fields.maintenance.single.plannedFrom')}</InputLabel>
                                <DateTimeInput value={maintenanceForm?.plannedFrom} onChange={(value) => handleSetFormValue({plannedFrom: value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="plannedUntil">{t('fields.maintenance.single.plannedUntil')}</InputLabel>
                                <DateTimeInput value={maintenanceForm?.plannedUntil} onChange={(value) => handleSetFormValue({plannedUntil: value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="reportedBy">{t('fields.maintenance.single.reportedBy')}</InputLabel>
                                {maintenanceForm?.reportedBy}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="reportedBy">{t('fields.maintenance.single.reportedAt')}</InputLabel>
                                {formatDateTimeForDisplay(maintenanceForm?.reportedAt)}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="updatedBy">{t('fields.maintenance.single.updatedBy')}</InputLabel>
                                {maintenanceForm?.updatedBy}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="updatedAt">{t('fields.maintenance.single.updatedAt')}</InputLabel>
                                {formatDateTimeForDisplay(maintenanceForm?.updatedAt)}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <Form.Check
                                    type='checkbox'
                                    id='notifyUser'
                                    label={t('fields.maintenance.single.notifyUsers')}
                                    onChange={e => handleSetFormValue({notifyUsers: e.target.checked})}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm} disabled={maintenanceState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};
