import React, { FC, useEffect } from 'react';
import styled from "styled-components";
import { MuiChipsInput } from 'mui-chips-input';
import isEmail from 'validator/lib/isEmail';
import { t } from 'i18next';


interface Props {
    value? : string[];
    onChange: (value: string[]) => void;
}

export const EmailMultiValueInput: FC<Props> = (props) => {
    const [chips, setChips] = React.useState<any>([])

    useEffect(() => {
        setChips(props.value);
    }, [props.value]);

    const handleChange = (newChips: string[]) => {
        const allChips= splitEmailAddressesArray(newChips)
        setChips(allChips);
        props.onChange(allChips);
    }

    function containsValidEmailAddresses(newValue: string): boolean {
        const emailAddresses = splitEmailAddresses(newValue);
        return emailAddresses.every(emailAddress => isEmail(emailAddress))
    }

    function splitEmailAddressesArray(emailAddresses: string[]): string[] {
        if (emailAddresses && emailAddresses.length > 0) {
            return emailAddresses.map(element => splitEmailAddresses(element))
                .reduce((a, b) => a.concat(b))
                .filter((value, index, self) => self.indexOf(value) === index)
        } else {
            return [];
        }
    }

    function splitEmailAddresses(emailAddresses: string): string[] {
        if (emailAddresses && emailAddresses.includes(",")) {
            return emailAddresses.split(",")
                .map(value => value.trim());
        }
        if (emailAddresses && emailAddresses.includes(" ")) {
            return emailAddresses.split(" ")
                .map(value => value.trim());
        }
        return [emailAddresses]
    }

    return (
        <MuiChipsInput style={{width:'100%'}} value={chips} onChange={handleChange} validate={(chipValue) => {
            return {
                isError: !containsValidEmailAddresses(chipValue),
                textError: t('error.invalidEmail')
            }
        }}/>
    );
}

const ArrowedSquare = styled.div`
    &:focus {
        outline: none;
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    padding: 20px;
    padding-bottom: 0px;
    border: #ced4da solid 2px;

    border-style: dashed;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
`;
