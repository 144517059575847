import React, { FC, useEffect, useState } from 'react';
import { authSelector, getServices, Service, servicesSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FeedbackQuestion, feedbackSelector, getFeedback, saveFeedback, ServiceFeedbackRequest } from '../../../state/slices/feedback';
import { DateTimeInput, ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CardHeader, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputLabel, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { ServiceSelectModalPanel } from '../../molecules/ServiceSelectModalPanel';
import { FeedbackForm } from '../../../state/slices/feedback/FeedbackForm';
import { ImageInput } from '../../molecules/ImageInput';
import { QuestionEditModalPanel } from '../../molecules/QuestionEditModalPanel';
import MainCard from '../../molecules/MainCard';
import { PopupTransition } from '../../atoms/Transitions';
import { Add, Edit, ScanBarcode, Send2, Trash } from 'iconsax-react';

interface Props {
}

export const FeedbackDetailContent: FC<Props> = () => {
    const [showQR, setShowQR] = useState(false);
    const [feedbackForm, setFeedbackForm] = useState(new FeedbackForm());
    const [serviceFeedbackRequest, setServiceFeedbackRequest] = useState<ServiceFeedbackRequest>();
    const [showAddService, setShowAddService] = useState(false);
    const [showEditQuestion, setShowEditQuestion] = useState(false);
    const [selectedServices, setSelectedServices] = useState<ServiceFeedbackRequest[]>([]);
    const [selectedQuestion, setSelectedQuestion] = useState<FeedbackQuestion>();
    const [questions, setQuestions] = useState<FeedbackQuestion[]>([]);
    const [addPending, setAddPending] = useState(false);
    let {feedbackId} = useParams();
    const url = new URL(window.location.href);
    const feedback = useSelector(feedbackSelector);
    const services = useSelector(servicesSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (feedbackId) {
            dispatch(getFeedback({feedbackId: feedbackId, jwtToken: authState.jwtToken}))
        }
    }, [feedbackId, dispatch]);
    useEffect(() => {
        if (feedback.feedback && feedback.feedback.id.toString() === feedbackId) {
            setSelectedServices(Object.assign([], feedback.feedback.services));
            setQuestions(Object.assign([], feedback.feedback.questions));
            setFeedbackForm(Object.assign(feedbackForm, feedback.feedback))
        }
    }, [feedback.feedback]);
    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken}));
    }, []);

    const handleClose = () => setShowQR(false);

    const handleShowQR = (serviceFeedbackRequest: ServiceFeedbackRequest) => {
        setServiceFeedbackRequest(serviceFeedbackRequest);
        setShowQR(true);
    }

    const handleShowAddService = () => {
        setShowAddService(true);
    }

    const handleCloseAddService = () => {
        setShowAddService(false);
    }

    const handleDeleteService = (serviceFeedbackRequest: ServiceFeedbackRequest) => {
        setSelectedServices(selectedServices?.filter((service) => service.externalKey !== serviceFeedbackRequest.externalKey));
    }

    const handleAddService = (serviceKey: string) => {
        setShowAddService(false);
        if (selectedServices?.filter((service) => service.service.externalKey === serviceKey).length) {
            return;
        }
        let service: ServiceFeedbackRequest = {
            externalKey: 'service:' + serviceKey,
            service: services.list?.filter((service) => service.externalKey === serviceKey)[0]
        }
        selectedServices?.push(service);
        setSelectedServices(selectedServices);
    }

    const handleSetFormValue = (param: any) => {
        setFeedbackForm(Object.assign({}, feedbackForm, param));
    }

    const handleShowEditQuestion = (question?: FeedbackQuestion) => {
        setSelectedQuestion(question);
        setShowEditQuestion(true);
    }

    const handleCloseEditQuestion = () => {
        setShowEditQuestion(false);
        setSelectedQuestion({});
    }

    const handleSaveQuestion = (question: FeedbackQuestion) => {
        setSelectedQuestion({});
        setShowEditQuestion(false);

        if (!question.id) {
            let newQuestion = Object.assign({}, question)
            newQuestion.id = Math.min(...questions!.map(question => question.id ? question.id : 0), 0) - 1;
            questions?.push(newQuestion);
        } else {
            let index = questions?.findIndex(q => q.id === question.id);
            if ((index > -1) && (questions !== undefined)) {
                questions[index] = Object.assign({}, question);
            }
        }
        setQuestions(questions);
    }

    const handleDeleteQuestion = (deleteQuestion: FeedbackQuestion) => {
        setQuestions(questions?.filter((question) => question.id !== deleteQuestion.id));
    }

    function saveFeedbackForm() {
        setAddPending(true);
        feedbackForm.questions = questions;
        feedbackForm.services = selectedServices;
        dispatch(saveFeedback({feedbackForm: feedbackForm, jwtToken: authState.jwtToken}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/feedback/list')
                    }, 250);
                }
            })
            .finally(() => {
                setAddPending(false);
            });
    }

    function isFormValid(): boolean {
        feedbackForm.services = selectedServices;
        feedbackForm.questions = questions;
        return FeedbackForm.isValid(feedbackForm);
    }

    function showResults() {
        navigate('/manage/feedback/' + feedbackId + '/results');
    }

    function copyToClipboard(url: string) {
        navigator.clipboard.writeText(url);
    }

    function handleInvite(service: Service) {
        navigate('/manage/feedback/' + feedbackId + '/' + service.externalKey + "/invite");
    }
    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={feedback.error !== undefined}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <MainCard title={feedbackId ? t('feedback.form.edit') : t('feedback.form.add')}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <QuestionEditModalPanel selectedQuestion={selectedQuestion} show={showEditQuestion}
                                                        onSave={question => handleSaveQuestion(question)}
                                                        onHide={handleCloseEditQuestion} />
                                <ServiceSelectModalPanel show={showAddService} services={services.list}
                                                         onSelect={service => handleAddService(service.externalKey)}
                                                         onHide={handleCloseAddService} />
                                <Dialog
                                    maxWidth="sm"
                                    TransitionComponent={PopupTransition}
                                    keepMounted
                                    fullWidth
                                    onClose={handleClose}
                                    open={showQR}
                                    sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                                    aria-describedby="alert-dialog-slide-description">
                                    <DialogTitle>{serviceFeedbackRequest?.service.name} - {serviceFeedbackRequest?.service.location}</DialogTitle>
                                    <DialogContent>
                                        <QRCodeCanvas
                                            value={url.protocol + '//' + url.host + '/feedback/' + feedback.feedback?.tenantKey + '/' + serviceFeedbackRequest?.externalKey ?? ''}
                                            size={300}/>
                                        <div>
                                            <a href={url.protocol + '//' + url.host + '/feedback/' + feedback.feedback?.tenantKey + '/' + serviceFeedbackRequest?.externalKey ?? ''}
                                               target="_blank">{url.protocol + '//' + url.host + '/feedback/' + feedback.feedback?.tenantKey + '/' + serviceFeedbackRequest?.externalKey ?? ''}</a>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="title">{t('feedback.form.title')}</InputLabel>
                                        <TextField type="text"
                                                   value={feedbackForm.title}
                                                   onChange={e => handleSetFormValue({title: e.target.value})}
                                                   inputProps={{ maxLength: 100 }}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="description">{t('feedback.form.description')}</InputLabel>
                                        <TextField type="text" multiline rows={5}
                                                   value={feedbackForm.description}
                                                   onChange={e => handleSetFormValue({description: e.target.value})}
                                                   inputProps={{ maxLength: 500 }}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="thankYouDescription">{t('feedback.form.thankYouDescription')}</InputLabel>
                                        <TextField type="text" multiline rows={3}
                                                   value={feedbackForm.thankYouDescription}
                                                   onChange={e => handleSetFormValue({thankYouDescription: e.target.value})}
                                                   inputProps={{maxLength: 500}}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="startDate">{t('feedback.form.startDate')}</InputLabel>
                                        <DateTimeInput value={feedbackForm.startDate} onChange={(value) => handleSetFormValue({startDate: value})}
                                                       />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="endDate">{t('feedback.form.endDate')}</InputLabel>
                                        <DateTimeInput value={feedbackForm.endDate} onChange={(value) => handleSetFormValue({endDate: value})}
                                                       />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="image">{t('feedback.form.image')}</InputLabel>
                                        <ImageInput existingImagePreview={feedbackForm.image}
                                                    onImageSelected={(image) => handleSetFormValue({image: image})}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={0}>
                                        <CardHeader title="Vragen" />
                                    </Stack>
                                </Grid>
                                <TableContainer>
                                    <Table sx={{ minWidth: 320 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ pl: 3 }}>Titel</TableCell>
                                                <TableCell >Omschrijving</TableCell>
                                                <TableCell >Soort</TableCell>
                                                <TableCell sx={{ pr: 3 }} align="right">Acties</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {questions?.map(question => (
                                                <TableRow hover key={question.id}>
                                                    <TableCell sx={{ pl: 3 }} component="th" scope="row">
                                                        {question.title}
                                                    </TableCell>
                                                    <TableCell >{question.description}</TableCell>
                                                    <TableCell >{t('feedback.form.question.questionTypes.' + question.questionType)}</TableCell>
                                                    <TableCell sx={{ pr: 3 }} align="right">
                                                        <Grid item>
                                                            <IconButton color={'primary'}
                                                                        onClick={() => handleShowEditQuestion(question)}><Edit /></IconButton>
                                                            <IconButton color={'error'}
                                                                        onClick={() => handleDeleteQuestion(question)}><Trash /></IconButton>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <IconButton color={'primary'} onClick={() => handleShowEditQuestion({
                                    id: 0,
                                    title: '',
                                    description: '',
                                    questionType: 'FIVE_STARS',
                                    possibleAnswers: []
                                })}><Add/></IconButton>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item><Button variant="outlined" color="secondary"
                                                   onClick={() => navigate(-1)}>{t('generic.back')}</Button></Grid>
                                <Grid item><Button variant="contained" onClick={saveFeedbackForm}
                                                   disabled={addPending || !isFormValid()}>{t('generic.save')}</Button></Grid>
                                <Grid item><Button variant="outlined"
                                                   onClick={showResults}>{t('feedback.form.results')}</Button></Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <MainCard title="Diensten">
                        <Grid container spacing={1}>
                            {
                                selectedServices?.map(serviceFbr => (
                                        <Grid item xs={12} key={"sfbr" + serviceFbr.service.externalKey}>
                                            <Grid container spacing={3} justifyContent="space-between">
                                                <Grid item>{serviceFbr.service.name} - {serviceFbr.service.location}</Grid>
                                                <Grid item>
                                                    <Grid container spacing={1}>
                                                        {
                                                            !serviceFbr.externalKey.startsWith('serviceFbr:') ?
                                                                <Grid item><IconButton color={'primary'} onClick={() => handleInvite(serviceFbr.service)}><Send2/></IconButton></Grid> : null
                                                        }
                                                        {
                                                            !serviceFbr.externalKey.startsWith('serviceFbr:') ?
                                                                <Grid item><IconButton color={'primary'} onClick={() => handleShowQR(serviceFbr)}><ScanBarcode/></IconButton></Grid> : null
                                                        }
                                                        <Grid item><IconButton color={'error'}
                                                                               onClick={() => handleDeleteService(serviceFbr)}><Trash/></IconButton></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                )
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={10}></Grid>
                                    <Grid item xs={2} justifyContent="flex-end">
                                        <IconButton color={'primary'}
                                                    onClick={() => handleShowAddService()}><Add/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Container>
    );
}


