import React, {useMemo} from 'react';

// material-ui
import {Theme} from '@mui/material/styles';
import {Box, useMediaQuery} from '@mui/material';

// project-imports
import Search from './Search';
import Profile from './Profile';
import Localization from './Localization';
import MobileSection from './MobileSection';
import MegaMenuSection from './MegaMenuSection';

import useConfig from '../../../../../hooks/useConfig';
import DrawerHeader from '../../Drawer/DrawerHeader';

// type
import {LAYOUT_CONST} from '../../../../atoms/types/config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {/*{!downLG && megaMenu}*/}
      {/*{!downLG && localization}*/}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}

      {/*<Notification />*/}
      {/*<Message />*/}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
