import React, {FC} from 'react';
import {t} from 'i18next';
import {Service} from '../../state';
import {ListItemText, MenuItem, Select} from '@mui/material';
import { ServiceFeedbackRequest } from '../../state/slices/feedback';

interface Props {
    selectedServiceFeedbackExternalKey?: string;
    onSelect: (serviceFeedbackRequest: ServiceFeedbackRequest) => void;
    serviceFeedbackRequests?: ServiceFeedbackRequest[];
    disabled?: boolean;
}


export const ServiceFeedbackRequestSelectCombo: FC<Props> = (props) => {
    function onSelectItem(serviceKey: string) {
        props.onSelect(props.serviceFeedbackRequests!.filter((serviceFeedbackRequest) => serviceFeedbackRequest.externalKey === serviceKey)[0]);
    }

    function getServiceLabel(selectedService: string):string {
        const serviceFeedbackRequest = props.serviceFeedbackRequests!.filter((service) => service.externalKey === selectedService)[0];
        return (serviceFeedbackRequest) ? serviceFeedbackRequest.service.name + (serviceFeedbackRequest.service.location ? ' - ' + serviceFeedbackRequest.service.location : '') : '';
    }

    return (
        <Select value={props.selectedServiceFeedbackExternalKey ? props.selectedServiceFeedbackExternalKey : ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                disabled={props.disabled}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary='Alle diensten'/>;
                    }

                    return <ListItemText primary={getServiceLabel(selected)}/>;
                }}>
            <MenuItem key='all' value=''>
                <ListItemText primary='Alle diensten' />
            </MenuItem>
            {props.serviceFeedbackRequests?.map((serviceFeedbackRequest) => (
                <MenuItem key={serviceFeedbackRequest.externalKey} value={serviceFeedbackRequest.externalKey}>
                    <ListItemText primary={serviceFeedbackRequest.service.name + (serviceFeedbackRequest.service.location ? ' - ' + serviceFeedbackRequest.service.location : '')} />
                </MenuItem>))}
        </Select>
    )
};

ServiceFeedbackRequestSelectCombo.defaultProps = {
    disabled: false
}
