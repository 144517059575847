import { createSlice } from '@reduxjs/toolkit';

export interface TenantConfig {
    colorHeaderPrimary: string;
    colorHeaderSecondary: string;
    colorHeaderText: string;
    colorCredentialListFilterBackgroundColor: string;
    logoPosition: string;
    primaryButtonColor: string;
    primaryButtonHoverColor: string;
    primaryButtonTextColor: string;
    urlPrefix: string;
}

export interface TenantConfigState {
    loading: boolean;
    error: string | undefined;
    tenantConfig: TenantConfig;
}

const INITIAL_STATE: TenantConfigState = {
    loading: true,
    error: undefined,
    tenantConfig: {
        colorHeaderPrimary: "#FFFFFF",
        colorHeaderSecondary: "#FFFFFF",
        colorHeaderText: "#000000",
        colorCredentialListFilterBackgroundColor: "#FFFFFF",
        logoPosition: 'center',
        primaryButtonColor: '#ffb612',
        primaryButtonHoverColor: '#ffe9b8',
        primaryButtonTextColor: '#000000',
        urlPrefix: 'http://localhost:3000'
    },
};

export const tenantConfigSlice = createSlice({
    name: 'tenantConfig',
    initialState: INITIAL_STATE,
    reducers: {}
});

