import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {Grid} from '@mui/material';
import styled from "styled-components";


interface ItemProps {
    value: number;
    children: ReactNode;
}

export const WidgetGroupSelectItem: FC<ItemProps & PropsWithChildren> = (props) => {
    return (<div>{props.children}</div>);
}

interface Props {
    value?: number;
    onChange: (selectedValue: number) => void;
    children?: ReactNode;
}

const SelectionWrapper = styled.div<{ selected: boolean }>`
  filter: grayscale(${({selected}) => selected ? 0 : 100});
  transition: transform .2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    filter: grayscale(0%);
  }
;
`;

export const WidgetGroupSelect: FC<Props & PropsWithChildren> = (props) => {

    function getChilderen() {
        return React.Children.map(props.children, child => {
                if (React.isValidElement(child)) {
                    // @ts-ignore
                    const widgetGroupSelectItem = (child as WidgetGroupSelectItem);
                    return (
                        <SelectionWrapper
                            selected={widgetGroupSelectItem.props.value == props.value}
                            onClick={event => {
                                props.onChange(widgetGroupSelectItem.props.value)
                            }}>
                            {child}
                        </SelectionWrapper>)
                }
            }
        );
    }

    return (
        <Grid container direction="row">
            {getChilderen()}
        </Grid>
    )

};


