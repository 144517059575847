import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../state";
import {useSelector} from 'react-redux';
import {Container} from 'react-bootstrap';
import {
    FeedbackAnswer,
    FeedbackQuestion,
    feedbackSelector,
    getFeedbackByKey,
    sendFeedback
} from '../../../../state/slices/feedback';
import {TitleText} from '../../../atoms';
import {ErrorAlert} from '../../../molecules';
import {t} from 'i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {getAppTheme} from '../../../AppTheme';
import styled from 'styled-components';
import {Box, Button, Grid, IconContainerProps, Rating} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {Question} from '../../../molecules/Question';
import "swiper/css";
import "swiper/css/pagination";

interface Props {
}

export const FeedbackRequestContent: FC<Props> = () => {
    let {feedbackKey} = useParams();
    const [answers, setAnswers] = useState([] as FeedbackAnswer[]);
    const [errorSending, setErrorSending] = useState(false);
    const [addPending, setAddPending] = useState(false);
    const dispatch = useAppDispatch();
    const feedback = useSelector(feedbackSelector);
    let navigate = useNavigate();
    useEffect(() => {
        dispatch(getFeedbackByKey({feedbackKey: feedbackKey}));
    }, []);

    function submitFeedback(event: React.SyntheticEvent) {
        setAddPending(true);
        event.preventDefault();
        dispatch(sendFeedback({externalKey: feedbackKey, answers}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/feedback/' + feedbackKey + '/sent');
                    }, 250);
                } else {
                    setErrorSending(true);
                }
            }).finally(() => {
            setAddPending(false);
        });
    }

    function changeAnswer(answer: FeedbackAnswer) {
        let existingAnswer = answers.find(value => value.questionId === answer.questionId);
        if (!existingAnswer) {
            existingAnswer = {questionId: answer.questionId, rating: answer.rating, text: answer.text};
            answers.push(existingAnswer);
        } else {
            existingAnswer.rating = answer.rating;
            existingAnswer.text = answer.text;
        }
        setAnswers(Object.assign([], answers));
    }

    function isFormValid(): boolean {
        let mandatoryQuestions = feedback.feedback?.questions?.filter((question) => question.questionType !== 'DESCRIPTION');
        if (!mandatoryQuestions) {
            return false;
        }
        return mandatoryQuestions.every((question) => isAnwered(question));
    }

    function isAnwered(question: FeedbackQuestion) {
        return answers.find(answer => answer.questionId === question.id);
    }

    return (
        <Container>
            <ErrorAlert errorMessage={t('error.retrievingData')} show={feedback.error !== undefined}/>
            <ErrorAlert errorMessage='Er is een fout opgetreden bij het insturen van je feedback' show={errorSending}/>
            <Grid container spacing={3}>
                {/*<Grid item xs={12} md={8} ml={3} mr={3}>*/}
                <HeaderItem item xs={12} md={8} ml={3}>
                    <TitleText>Feedback {feedback.feedback?.service?.name}</TitleText>
                </HeaderItem>
                <HeaderItem item xs={12} md={8} ml={3} pb={5}>
                    <Box>{'Wij horen graag je mening over deze ' + feedback.feedback?.service?.name + ' in ' + feedback.feedback?.service?.location}</Box>
                </HeaderItem>
                {/*</Grid>*/}
                <Grid item xs={12} md={8} ml={3} mr={3} mb={7}>
                    <LargeText>{feedback.feedback?.title}</LargeText><br/>
                    <Box mb={3}>{feedback.feedback?.description}</Box>
                    <img src={feedback.feedback?.image} width={300}/>
                </Grid>

                {
                    feedback.feedback?.questions?.map((question, index) => (
                        <Grid item xs={12} md={8} ml={3} mr={3} mb={7} key={'question' + index}>
                            <Question question={question} changeAnswer={changeAnswer}></Question>
                        </Grid>

                    ))
                }
                <Grid item xs={12} md={8} m={3}>
                    <Button variant="contained" fullWidth onClick={submitFeedback}
                            disabled={addPending || !isFormValid()}>{'Feedback versturen'}</Button>
                </Grid>
            </Grid>
        </Container>
    )
};

const HeaderItem = styled(Grid)`
  background-color: ${getAppTheme().colors.tint2};
  color: ${getAppTheme().colors.primary};
  font-family: ${getAppTheme().generic.fontFamily};
`;


const LargeText = styled(Box)`
  font-size: 20px;
`;

const StyledRating = styled(Rating)(({theme}) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error"/>,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error"/>,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning"/>,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success"/>,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success"/>,
        label: 'Very Satisfied',
    },
};

function IconContainer(props: IconContainerProps) {
    const {value, ...other} = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

